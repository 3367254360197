export const projectSections = {
    ALL: 'All',
    WEB_DEV: 'Web',
    AI: 'AI',
    GAME_DEV: 'Games',
    APP_DEV: 'Apps',
};

export const tech = {
    UNITY: 'Unity',
    RESEARCH: 'Research',
    STEAM_VR: 'Steam VR',
    REACT: 'React',
    NODE: 'Node',
    EXPRESS: 'Express',
    MONGO_DB: 'MongoDB',
    RETHINK_DB: 'RethinkDB',
    REACT_NATIVE: 'React Native',
    FIGMA: 'Figma',
    JAVA: 'Java',
    PYTHON: 'Python',
    TENSORFLOW: 'TensorFlow 2.0',
    AWS: 'AWS',
    EC2: 'AWS EC2',
    S3: 'AWS S3',

    MAYA: 'Maya',
    PHOTOSHOP: 'Photoshop',
    ARNOLD: 'Arnold',
};

export const projects = [
    {
        imgs: [1, 2, 3].map((num) => require(`../assets/img/starcrossed${num}.png`)),
        title: 'Star Crossed',
        subtitle: 'Short Film',
        description:
            'Star Crossed tells the story of two traffic people torn between love and the duty to serve the people of their city. During production, I worked on modeling and texturing, primarily the road, sidewalk, crosswalk, traffic lights, and flower sticker, as well as creating the gray "moody" lighting used towards the end. For storyboarding, layout, and animation, I worked on shots between 0:48 and 1:35, interfacing with a remote renderfarm to create the final product.',
        link: 'https://vimeo.com/492508771',
        techstack: [tech.MAYA, tech.ARNOLD, tech.PHOTOSHOP],
        tags: [],
        id: Math.random(),
        display: 'web',
        thumbnail: require('../assets/img/starcrossed_thumb.png'),
        period: 'Fall 2020',
    },
    {
        imgs: [1, 2, 3, 4].map((num) => require(`../assets/img/pangea${num}.png`)),
        title: 'Pangea.app',
        subtitle: 'Software Engineer Intern',
        description:
            'Pangea.app connects talented college students with up-and-coming businesses. I currently work full-time as a Software Engineer for Pangea.app through the summer of 2020, and will continue working part-time in fall/winter 2020. I work across the technology stack, using React, Redux, and Material UI for the web app frontend, React Native and Redux for the mobile app, and RethinkDB for the backend.',
        link: 'https://www.pangea.app',
        techstack: [tech.REACT, tech.REACT_NATIVE, tech.FIGMA, tech.RETHINK_DB],
        tags: [projectSections.WEB_DEV, projectSections.APP_DEV],
        id: Math.random(),
        display: 'web',
        thumbnail: require('../assets/img/pangea_thumb.svg'),
        period: 'Summer 2020',
    },
    {
        imgs: [1, 2, 3, 4, 5, 6, 7].map((num) => require(`../assets/img/jazz${num}.png`)),
        title: 'The History of Jazz in France',
        subtitle: 'Static React Web App Hosted on AWS S3',
        description:
            'During my semester abroad in Paris for the Spring 2020, I conducted research on the history of jazz in France throughout the 20th century. To present my research, I designed and developed a web app with React hosted on AWS S3 that includes a timeline of events, weekly reflections, and links to related media.',
        link: 'http://glisp.s3-website.us-east-2.amazonaws.com/',
        techstack: [tech.REACT, tech.AWS, tech.RESEARCH],
        id: Math.random(),
        tags: [projectSections.WEB_DEV],
        display: 'web',
        thumbnail: require('../assets/img/jazz_thumb.png'),
        period: 'Spring 2020',
    },
    // {
    //     imgs: [1, 2, 3, 4, 5].map((num) =>
    //         require(`../assets/img/internally${num}.png`)
    //     ),
    //     title: 'InternAlly',
    //     subtitle: 'Homepage construction from mockups',
    //     description:
    //         "InternAlly’s goal is to create a web platform and application through which students looking for jobs in business, arts, humanities, etc. have the tools they need to excel in the recruitment process. I worked with InternAlly to develop their landing page in React, using mockups created by the team's designer.",
    //     link: 'https://www.github.com/gkupsaw/intern-ally',
    //     techstack: [tech.REACT],
    //     id: Math.random(),
    //     tags: [projectSections.WEB_DEV],
    //     display: 'web',
    //     thumbnail: require('../assets/img/internally_thumb.png'),
    //     period: 'Spring 2020',
    // },
    {
        imgs: [1, 2].map((num) => require(`../assets/img/stylegan${num}.png`)),
        title: 'Conditional StyleGAN',
        subtitle: 'Research and Implementation',
        description:
            "As a final project for a course on deep learning, I researched conditional GANs and the StyleGAN architecture in order to implement a hybrid model. The model's goal was to generate high-quality album covers given a string input representing a genre. It was an ambitious task, and as you can see from the thumbnail, it's still a work in progress. Built using Tensorflow 2.0 (Python).",
        techstack: [tech.PYTHON, tech.TENSORFLOW, tech.RESEARCH],
        id: Math.random(),
        link: 'https://github.com/gkupsaw/cond-stylegan',
        tags: [projectSections.AI],
        display: 'mobile',
        thumbnail: require('../assets/img/stylegan_thumb.png'),
        period: 'Fall 2019',
    },
    {
        imgs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((num) => require(`../assets/img/kps${num}.png`)),
        title: "Katie's Picture Show",
        subtitle: 'Unity/SteamVR Immersive Experience',
        description:
            "A virtual reality experience that takes you into Katie's magical museum, where you can jump into paintings and interact with them in a 3D environment. Based off the children's book \"Katie's Picture Show\" and developed in Unity with SteamVR. My team team followed the Scrum/Agile framework through daily standups in a Slack channel, submitting code through Github, and managing tasks on Trello.",
        techstack: [tech.UNITY, tech.STEAM_VR],
        link: 'https://www.github.com/gkupsaw/katies-picture-show',
        id: Math.random(),
        tags: [projectSections.GAME_DEV],
        display: 'web',
        thumbnail: require('../assets/img/kps_thumb.png'),
        period: 'Fall 2019',
    },
    {
        imgs: [1, 2, 3, 4, 5, 6, 7].map((num) => require(`../assets/img/mario${num}.png`)),
        title: 'Java Mario Bros.',
        subtitle: 'Game Development',
        description:
            "This recreation of the first level of Super Mario Bros. in Java was my first large coding project. I've always been rather sentimental about this one, as it was my first experience in continuing my learning independently (i.e. outside of class) and making my ideas reality through code.",
        techstack: [tech.JAVA],
        link: 'https://www.github.com/gkupsaw/Mario',
        id: Math.random(),
        tags: [projectSections.GAME_DEV],
        display: 'web',
        thumbnail: require('../assets/img/mario_thumb.png'),
        period: 'Spring 2019',
    },
];

export const smallerProjects = [
    {
        imgs: [1, 2, 3, 4, 5, 6].map((num) => require(`../assets/img/ftr${num}.png`)),
        title: 'For the Record',
        subtitle: 'Tech lead for React Native app development',
        description:
            'My first major freelancing project - For the Record is a highly specialized sports trivia game for the most knowledgable sports buffs. Using high-level ideas and goals from the client as a guide, I created this React Native mobile app with a Node/MongoDB backend hosted with AWS EC2.',
        techstack: [tech.REACT_NATIVE, tech.NODE, tech.MONGO_DB, tech.EC2],
        id: Math.random(),
        tags: [projectSections.APP_DEV],
        display: 'mobile',
        thumbnail: require('../assets/img/ftr_thumb.png'),
    },
    {
        imgs: [1, 2, 3, 4, 5, 6].map((num) => require(`../assets/img/facilitay${num}.png`)),
        title: 'Facilitay',
        subtitle: 'Freelance web dev',
        description:
            "My first freelancing project - Facilitay is a service that aims to help make technology accesible to older populations in order to create a more connected world. I redesigned my client's entire website and created a new static site with React hosted on AWS S3.",
        techstack: [tech.REACT, tech.S3],
        link: 'https://www.facilitay.net',
        id: Math.random(),
        tags: [projectSections.WEB_DEV],
        display: 'web',
        thumbnail: require('../assets/img/facilitay_thumb.png'),
        period: 'Fall 2019',
    },
    {
        imgs: [0].map((num) => require(`../assets/img/divvyup${num}.png`), []),
        title: 'Divvyup',
        subtitle: 'Full stack JS web app deployed with AWS',
        description:
            'A full-stack Javascript website I created with a team and a real-world client during a course on web development at Brown. Divvyup is a web app that streamlines the process of paying people back hosts for attending their events, whether it be before, during, or after the party.',
        techstack: [tech.REACT, tech.NODE, tech.MONGO_DB, tech.AWS],
        link: 'https://www.github.com/gkupsaw/divvyup',
        id: Math.random(),
        tags: [projectSections.WEB_DEV],
        display: 'web',
        thumbnail: require('../assets/img/divvyup_thumb.png'),
    },
];

export const phoneDimensions = {
    width: 90,
    height: 180,
    contentWidth: 84,
    contentHeight: 147,
};
export const computerDimensions = {
    width: 240,
    height: 170,
    contentWidth: 214,
    contentHeight: 124,
};

export default {
    projectSections,
    tech,
    projects,
    phoneDimensions,
    computerDimensions,
};
