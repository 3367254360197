/**
 * ThemePalette renders a list of dots that, when pressed, update the color theme of the parent.
 * This is most effective when setTheme is a Redux action, or some other function that affects the entire app state.
 */

import { React, Component, Proptypes } from '../../imports';

class ThemePalette extends Component {
    render() {
        const { themes, dotRadius, dotSpacing, setTheme } = this.props;
        return (
            <svg
                height={dotRadius * 2}
                width={
                    themes.length * dotRadius * 2 +
                    (themes.length - 1) * dotSpacing
                }
            >
                {themes.map((theme, i) => (
                    <circle
                        key={i}
                        cx={i * dotRadius * 2 + i * dotSpacing + dotRadius}
                        cy={dotRadius}
                        r={dotRadius}
                        onClick={() => setTheme(theme)}
                        style={{
                            cursor: 'pointer',
                            background: `linear-gradient(${theme.sample1}, ${theme.sample2} 100%)`,
                        }}
                    />
                ))}
            </svg>
        );
    }
}

ThemePalette.propTypes = {
    dotRadius: Proptypes.number,
    dotSpacing: Proptypes.number,
    themes: Proptypes.array.isRequired,
};

ThemePalette.defaultProps = {
    dotRadius: 50,
    dotSpacing: 30,
    themes: [],
};

export default ThemePalette;
