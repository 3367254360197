/*
 */

import {
    React,
    Component,
    Proptypes,
    styled,
    FlexCenteredColumn,
} from '../../imports';

export default class FadeinWrapper extends Component {
    ref = React.createRef();

    componentWillUnmount = () =>
        window.removeEventListener('scroll', this.fade);

    componentDidMount = () => {
        window.addEventListener('scroll', this.fade);
    };

    fade = () => {
        if (window.scrollY < this.props.fadeY) {
            this.ref.current.style.opacity = 1;
        } else {
            this.ref.current.style.opacity = 0;
        }
    };

    render() {
        return <Wrapper ref={this.ref} {...this.props} />;
    }
}

const Wrapper = styled(FlexCenteredColumn)`
    transition: 0.5s;
`;

FadeinWrapper.propTypes = {
    fadeY: Proptypes.number,
};

FadeinWrapper.defaultProps = {
    fadeY: 10000,
};
