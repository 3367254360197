/**
 * Portfolio is the screen displaying projects.
 */

import {
    React,
    Component,
    connect,
    styled,
    responsiveFontSize,
    projects,
    projectSections as sections,
    isWidthUp,
    isWidthDown,
} from '../../imports';

import {
    updatePopoverVisible,
    setProjectToShow,
} from '../../ducks/PopoverDuck';
import { Project } from '../project';
import { AnimatedList, OptionBar, ScrollIntoView } from '../common';

class Portfolio extends Component {
    state = {
        inView: false,
        selectedID: null,
        selectedSection: sections.ALL,
        optionsOpen: true,
    };

    render() {
        const { windowSize, theme } = this.props;
        const { inView, selectedID, selectedSection, optionsOpen } = this.state;
        const selectedProjects = projects.map((project) => ({
            component: (
                <Project
                    project={project}
                    key={project.id}
                    selectedID={selectedID}
                    skeletonColor={theme.skeleton}
                    imageStyle={{
                        boxShadow: `0 0 2px 2px white`,
                        backgroundColor: theme.backgroundPrimary,
                    }}
                    onSelect={() =>
                        this.setState({
                            selectedID: project.id,
                        })
                    }
                    onDeselect={() =>
                        this.setState({
                            selectedID: null,
                        })
                    }
                    showPopover={(nextProjectToShow) =>
                        this.props.setProjectToShow(nextProjectToShow)
                    }
                />
            ),
            id: project.id,
            itemData: project,
            hidden:
                selectedSection !== sections.ALL &&
                !project.tags.includes(selectedSection),
        }));

        const appearStyle = {
            transform: inView ? 'none' : 'translateX(-400px)',
            opacity: inView ? 1 : 0,
            transition: '0.5s',
        };

        return (
            <Wrapper
                offsetFromTop={window.innerHeight / 1.5}
                onScrollIntoView={() => this.setState({ inView: true })}
            >
                <Title color={theme.header} theme={theme} style={appearStyle}>
                    Featured Projects
                </Title>
                <OptionBar
                    isOpen={optionsOpen}
                    open={() => this.setState({ optionsOpen: true })}
                    close={() => this.setState({ optionsOpen: false })}
                    options={sections}
                    fancy={false}
                    font={theme.optionFont}
                    fontSelected={theme.optionFontSelected}
                    optionLength={
                        isWidthDown('sm', windowSize.width)
                            ? 30
                            : isWidthDown('md', windowSize.width)
                            ? 60
                            : 100
                    }
                    optionSpacing={
                        isWidthUp('md', windowSize.width) ? 40 : null
                    }
                    selectedOption={selectedSection}
                    onChangeOption={(nextSelectedSection) =>
                        this.setState({
                            selectedSection: nextSelectedSection,
                        })
                    }
                    style={{ ...appearStyle, transitionDelay: '0.25s' }}
                />
                <div
                    style={{
                        ...appearStyle,
                        transform: inView ? 'none' : 'translateY(-50px)',
                        transitionDelay: '0.5s',
                        width: '100%',
                    }}
                >
                    <AnimatedList
                        shrinkHidden
                        screenWidth={windowSize.width}
                        items={selectedProjects}
                        style={{ marginTop: 50 }}
                        itemsPerRowForGivenWidth={{
                            'xs': 1,
                            'sm': 2,
                            'md': 3,
                            'lg': 3,
                        }}
                    />
                </div>
            </Wrapper>
        );
    }
}

const Wrapper = styled(ScrollIntoView)`
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 10%;
    padding-bottom: 20vh;
`;

const Title = styled.h1`
    margin: 0;
    margin-bottom: 10px;
    font-size: ${responsiveFontSize({ min: 32, max: 50 })};
    color: ${({ color }) => color};
`;

const mapStateToProps = ({ sizes, themes, popover }) => {
    const { windowSize } = sizes;
    const { theme } = themes;
    const { popoverVisible } = popover;
    return {
        windowSize,
        theme,
        popoverVisible,
    };
};

export default connect(mapStateToProps, {
    updatePopoverVisible,
    setProjectToShow,
})(Portfolio);
