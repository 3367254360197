import {
    React,
    Component,
    Proptypes,
    styled,
    getItemWidth,
    getValForScreenWidth,
} from '../../imports';

class AnimatedList extends Component {
    state = {
        itemWidth: 0,
        itemsPerRow: 1,
        itemSpacing: getValForScreenWidth(this.props.itemSpacingMapping, 0),
        calculatingInitialValues: true,
    };
    containerRef = React.createRef();

    componentDidMount = () => {
        this.checkIfShouldUpdate();
    };

    componentDidUpdate(prevProps) {
        this.checkIfShouldUpdate(prevProps);
    }

    // only animate translate if not previously h

    checkIfShouldUpdate = (prevProps = {}) => {
        let newState = {};
        let shouldUpdate = false;

        // if screen and item sizes have been calculated for the first time
        if (this.state.itemWidth !== 0 && this.state.calculatingInitialValues) {
            shouldUpdate = true;
            newState = { ...newState, calculatingInitialValues: false };
        }

        // if screen changed size
        if (this.props.screenWidth !== prevProps.screenWidth) {
            shouldUpdate = true;

            // recalculate item spacing
            newState = {
                ...newState,
                itemSpacing: getValForScreenWidth(
                    this.props.itemSpacingMapping,
                    this.props.screenWidth
                ),
            };

            // recalculate item size
            newState = {
                ...newState,
                ...this._handleResize(
                    this.containerRef?.current?.clientWidth || 0,
                    this.props.itemsPerRowForGivenWidth,
                    this.props.screenWidth,
                    newState.itemSpacing
                ),
            };
        }

        if (shouldUpdate) this.setState(newState);
    };

    render() {
        const {
            itemWidth,
            itemsPerRow,
            itemSpacing,
            calculatingInitialValues,
        } = this.state;
        const { items, transitionTime, style } = this.props;
        const { positionedItems, numVisibleItems } = calculatingInitialValues
            ? { positionedItems: [], numVisibleItems: 0 }
            : this._renderPositionedItems(
                  items,
                  itemsPerRow,
                  itemWidth,
                  itemSpacing
              );

        return (
            <Container
                ref={this.containerRef}
                style={{
                    ...style,
                    height:
                        itemWidth *
                            (Math.floor(numVisibleItems / itemsPerRow) + 1) +
                        itemSpacing *
                            (Math.floor(numVisibleItems / itemsPerRow) + 1),
                    transition: `${transitionTime}ms ease-out`,
                }}
            >
                {positionedItems}
            </Container>
        );
    }

    _renderPositionedItems = (items, itemsPerRow, itemWidth, itemSpacing) => {
        let currentShowingItem = -1;
        const positionedItems = items.map((item, i) => {
            const { hidden, style, component } = item;
            if (!hidden) currentShowingItem++;
            let row, col;
            if (hidden) {
                row = Math.floor(i / itemsPerRow);
                col = i % itemsPerRow;
            } else {
                row = Math.floor(currentShowingItem / itemsPerRow);
                col = currentShowingItem % itemsPerRow;
            }

            const transform = this._getItemTransform({
                row,
                col,
                width: itemWidth,
                spacing: itemSpacing,
            });

            return (
                <Item
                    key={i}
                    style={{
                        transform,
                        transition: `${this.props.transitionTime}ms ease-out transform`,
                    }}
                >
                    <div
                        style={{
                            opacity: hidden ? 0 : 1,
                            transform: `scale(${hidden ? 0 : 1})`,
                            width: itemWidth,
                            height: itemWidth,
                            transition: `${this.props.transitionTime}ms ease-out transform, ${this.props.transitionTime}ms ease-out opacity`,
                            ...(style || {}),
                        }}
                    >
                        {component}
                    </div>
                </Item>
            );
        });
        return { positionedItems, numVisibleItems: currentShowingItem };
    };

    _getItemTransform = ({ row, col, width, spacing }) => {
        let transform;

        const offsetleft = col === 0 ? 0 : spacing * col;
        const offsettop = row === 0 ? 0 : spacing * row;
        transform = `translate(${col * width + offsetleft}px, ${
            row * width + offsettop
        }px)`;
        return transform;
    };

    _handleResize = (
        containerWidth,
        itemsPerRowForGivenWidth,
        screenWidth,
        itemSpacing
    ) => {
        const itemsPerRow = getValForScreenWidth(
            itemsPerRowForGivenWidth,
            screenWidth
        );
        const itemWidth = getItemWidth(
            containerWidth,
            itemsPerRow,
            itemSpacing
        );
        return {
            itemsPerRow,
            itemWidth,
        };
    };
}

const Container = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;

    width: 100%;
`;

const Item = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    transform-origin: 50% 50%;
    overflow: visible;
`;

AnimatedList.propTypes = {
    items: Proptypes.array.isRequired,
    itemsPerRowForGivenWidth: Proptypes.object,
    itemSpacingMapping: Proptypes.object,
    screenWidth: Proptypes.number,
    transitionTime: Proptypes.number,
    style: Proptypes.object,
};

AnimatedList.defaultProps = {
    items: [],
    screenWidth: 0,
    itemsPerRowForGivenWidth: { 'xs': 1, 'xl': 1 },
    itemSpacingMapping: { 'xs': 40, 'sm': 10 },
    transitionTime: 400,
    style: {},
};

export default AnimatedList;
