/**
 * GlowingArrow can flip between pointing up and down, and performs a glowing action when hovered.
 */

import { React, Component, Proptypes, styled, keyframes } from '../../imports';
import { TriangleDown } from '../../assets/icons';

const pulsate = keyframes`
    0% { transform: scale(1) opacity: 1 }
    100% { transform: scale(1.5) ; opacity: 0 }
`;

const DownCaret = styled(TriangleDown)`
    cursor: pointer;
    transition: 0.3s ease-out;
    font-size: 80px;
    stroke-width: 0.5;
    transition: 0.3s ease-out;
`;

const GlowingCaret = styled(DownCaret)`
    animation: ${pulsate} 1s ${({ delay }) => delay}s linear infinite;
`;

export default class GlowingArrow extends Component {
    state = { glow: false };

    _renderGlowArrows = () => {
        const {
            stroke,
            strokeWidth,
            numGlowingArrows,
            delayBetweenArrows,
        } = this.props;
        const Component = this.state.glow ? GlowingCaret : DownCaret;
        let glowingArrows = [];
        for (let i = 0; i < numGlowingArrows; i++) {
            glowingArrows.push(
                <Component
                    key={i}
                    delay={i * delayBetweenArrows}
                    strokeWidth={strokeWidth}
                    style={{
                        stroke,
                        fill: 'transparent',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                />
            );
        }
        return glowingArrows;
    };

    render() {
        const { myRef, fill, stroke, style, onClick, up } = this.props;
        return (
            <span
                ref={myRef}
                style={{
                    position: 'relative',
                    transition: '0.6s cubic-bezier(.45,-0.39,.67,.99)',
                    ...style,
                    transform: `rotate(${up ? 180 : 0}deg)`,
                }}
                onClick={onClick}
                onMouseEnter={() => this.setState({ glow: true })}
                onMouseLeave={() => this.setState({ glow: false })}
            >
                <DownCaret style={{ fill, stroke }} />
                {this._renderGlowArrows()}
            </span>
        );
    }
}

GlowingArrow.propTypes = {
    myRef: Proptypes.object,
    fill: Proptypes.string,
    stroke: Proptypes.string,
    style: Proptypes.object,
    onClick: Proptypes.func,
    up: Proptypes.bool,
    numGlowingArrows: Proptypes.number,
    delayBetweenArrows: Proptypes.number,
};

GlowingArrow.defaultProps = {
    myRef: null,
    fill: 'transparent',
    stroke: 'white',
    style: {},
    onClick: () => {},
    up: false,
    numGlowingArrows: 3,
    delayBetweenArrows: 0.7,
};
