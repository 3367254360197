/*
 *   App.js is the main component in the application, and is where different routes are defined
 */

import {
    React,
    Component,
    connect,
    BrowserRouter,
    Switch,
    Route,
} from './imports';

import { updateWindowSize } from './ducks/ObjectSizesDuck';
import Home from './components/Home';

/**
 * TODO:
 *  code explorer?
 *  fancy section transitions (hard scroll, https://smartslider3.com/product-showcase-page-mobile-app/)
 *  full screen projects
 *  tech icon confetti
 *  use handclick - i think i fixed the animation
 *
 * DONE:
 *  remove onesizeimage from popover, not a good fit
 *  pulsate arrow on hover
 *  image slide on scroll
 *  nav bar
 *  sort of done - shrink hidden items of animated list, no slide away
 *  change theme1 to colorTheme and all app colors to follow a theme
 *  abstract: contact form
 *  add "just fill out this form!" with a nice graphic for contact form
 *  document: animated lists, onesizeimage
 *  convert animations to FLIP
 */

class App extends Component {
    componentDidMount = () => {
        this._handleResize();
        window.addEventListener('resize', this._handleResize);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this._handleResize);
    };

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='*' component={Home} />
                </Switch>
            </BrowserRouter>
        );
    }

    _handleResize = () => {
        this.props.updateWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
}

export default connect(null, {
    updateWindowSize,
})(App);
