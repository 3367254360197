/**
 * common-styles.js is a file for abstract functions
 */

export const scroll = (ref) =>
    ref.current.scrollIntoView({ behavior: 'smooth' });

export const random = (min, max) => {
    return min + Math.random() * (max - min);
};

export const randInt = (min, max) => {
    return Math.floor(random(min, max));
};

export const responsiveFontSize = ({ min, max }) =>
    `calc(${min}px + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300)))`;
