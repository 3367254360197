// ------------------------------------ TYPES ------------------------------------
export const types = {
    UPDATE_WINDOW_SIZE: 'UPDATE_WINDOW_SIZE',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    windowSize: { width: 0, height: 0 },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_WINDOW_SIZE:
            return { ...state, windowSize: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const updateWindowSize = (windowSize) => ({
    type: types.UPDATE_WINDOW_SIZE,
    payload: windowSize,
});
