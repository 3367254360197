import { React, styled, Proptypes, FlexCenteredColumn } from '../../imports';

const Footer = ({ theme, copyrightMessage }) => {
    return (
        <Wrapper>
            {typeof copyrightMessage === 'string' ? (
                <Copyright style={{ color: theme.font4 }}>
                    {copyrightMessage}
                </Copyright>
            ) : (
                copyrightMessage?.map((h, i) => (
                    <Copyright
                        key={i}
                        style={{
                            color: theme.font4,
                            marginTop: i === 0 ? 0 : '1em',
                        }}
                    >
                        {h}
                    </Copyright>
                ))
            )}
        </Wrapper>
    );
};

const Wrapper = styled(FlexCenteredColumn)`
    position: relative;
    width: 100%;
    padding: 25px 0;
    white-space: pre-wrap;
`;

const Copyright = styled.h4`
    display: flex;
    align-items: flex-end;

    height: 100%;
    margin: 0;
    opacity: 0.7;
`;

Footer.propTypes = {
    theme: Proptypes.object.isRequired,
    copyrightMessage: Proptypes.oneOfType([Proptypes.string, Proptypes.array]),
};

Footer.defaultProps = {
    theme: {},
    copyrightMessage: '',
};

export default Footer;
