/**
 * common-styles.js is a file for frequently-used styled components
 */

import styled from 'styled-components';

export const FlexCentered = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
`;

export const FlexCenteredColumn = styled(FlexCentered)`
    flex-flow: column;
`;

export const Section = styled(FlexCenteredColumn)`
    position: relative;
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
`;
