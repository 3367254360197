/**
 * Contact displays contact information and the ContactForm.
 */

import {
    React,
    Component,
    connect,
    styled,
    responsiveFontSize,
    FlexCentered,
    isWidthDown,
} from '../../imports';

import { ScrollIntoView } from '../common';
import { ContactForm } from '../contact';

class Contact extends Component {
    state = { inView: false };

    render() {
        const { props, state } = this;
        const { windowSize, theme, links } = props;
        const { inView } = state;

        const mediumSceen = isWidthDown('md', windowSize.width);

        return (
            <Wrapper
                offsetFromTop={window.innerHeight / 1.5}
                onScrollIntoView={() => this.setState({ inView: true })}
            >
                <ContactForm
                    theme={theme}
                    style={{
                        marginBottom: 50,
                        flex: 1,
                        width: mediumSceen ? '50vw' : '30vw',
                    }}
                    focused={inView}
                    windowSize={windowSize}
                />
                <LinksWrapper>
                    {links.map(({ href, icon }, i) => (
                        <Link
                            key={href}
                            href={href}
                            rel='noopener noreferrer'
                            target={href.match('mailto') ? '' : '_blank'}
                            style={{ marginLeft: i === 0 ? 0 : 40 }}
                        >
                            <SocialIcon
                                as={icon}
                                style={{ color: theme.header }}
                            />
                        </Link>
                    ))}
                </LinksWrapper>
            </Wrapper>
        );
    }
}

const Wrapper = styled(ScrollIntoView)`
    display: flex;
    flex-direction: column;
    align-items: center;

    flex: 1;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
`;

const LinksWrapper = styled(FlexCentered)`
    margin-top: auto;
    margin-bottom: 20px;
`;

const Link = styled.a`
    &::before {
        content: none;
    }
`;

const SocialIcon = styled.svg`
    color: inherit;
    display: block;
    width: ${responsiveFontSize({ min: 35, max: 43 })};
    height: ${responsiveFontSize({
        min: 35,
        max: 43,
    })};

    border-radius: 6px;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
        transform: scale(0.85);
    }
`;

const mapStateToProps = ({ themes, sizes }) => {
    const { windowSize } = sizes;
    const { theme } = themes;
    return { windowSize, theme };
};

export default connect(mapStateToProps, {})(Contact);
