/**
 * About displays more information after the Welcome screen.
 */

import { React, Component, connect, responsiveFontSize, styled, FlexCentered, isWidthUp } from '../../imports';

import { ScrollIntoView } from '../common';

const textData = {
    header: 'About',
    paragraphs: [
        "Hey! I'm Griffin, as you probably saw above.",
        "I'm really into making things that people enjoy interacting with, whether it be a JavaScript game, UX for a web app, or the code that another developer's gonna be working with.",
        "Right now, I work full-time as an SDE for <a href='https://aws.amazon.com/organizations/'>AWS Organizations</a> on the Policies platform. In my free time, I'm into game development, jamming, and making good coffee.",
        'Here are some of the languages, frameworks, and software I find myself using most often:',
    ],
};
const transitionDelay = 0.25;

class About extends Component {
    state = { inView: false };

    renderText = (data, theme, inView) => {
        const smallScreen = !isWidthUp('sm', this.props.windowSize.width);
        const animationStyle = {
            transform: inView ? 'none' : 'translateY(-100px)',
            opacity: inView ? 1 : 0,
            transition: '0.5s transform, 0.5s opacity',
        };
        const header = (
            <Header
                key='header'
                style={{
                    color: theme.header,
                    ...animationStyle,
                }}
            >
                {data.header}
            </Header>
        );
        const paragraphs = data.paragraphs.map((textString, i) => {
            const splitByLinks = textString.split(/<a|<\/a>/g);
            return (
                <Text
                    key={`text-${i}`}
                    style={{
                        ...animationStyle,
                        transitionDelay: `${(i + 1) * transitionDelay}s`,
                        width: smallScreen ? '100%' : '80%',
                    }}
                >
                    {splitByLinks.map((textSlice, i2) => {
                        if (i2 % 2 === 0) return textSlice;
                        else {
                            const [href, title] = textSlice.split('>');
                            return (
                                <a
                                    key={`link-${i2}`}
                                    href={href.split(/href="|href='|"|'/g)[1]}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    style={{ color: theme.link }}
                                >
                                    {title}
                                </a>
                            );
                        }
                    })}
                </Text>
            );
        });
        return [header, ...paragraphs];
    };

    render() {
        const { props, state } = this;
        const { theme } = props;
        const { inView } = state;
        const smallScreen = !isWidthUp('sm', this.props.windowSize.width);
        const animationStyle = {
            transform: inView ? 'none' : 'translateY(-100px)',
            opacity: inView ? 1 : 0,
            transition: '0.5s transform, 0.5s opacity',
        };
        return (
            <Wrapper
                offsetFromTop={window.innerHeight / 1.5}
                onScrollIntoView={() => this.setState({ inView: true })}
                style={{
                    width: smallScreen ? '100%' : '80%',
                    padding: smallScreen ? '0 50px' : '',
                }}
            >
                {this.renderText(textData, theme, inView)}

                <ListWrapper
                    style={{
                        ...animationStyle,
                        transitionDelay: `${(textData.paragraphs.length + 1) * transitionDelay}s`,
                    }}
                >
                    {smallScreen ? (
                        <ul style={{ paddingInlineStart: 20 }}>
                            <li>AWS tooling</li>
                            <li>Java | Ruby</li>
                            <li>React | React Native</li>
                            <li>Node | Express</li>
                            <li>HTML | CSS</li>
                            <li>Figma</li>
                            <li>Photoshop</li>
                        </ul>
                    ) : (
                        <>
                            <ul style={{ paddingInlineStart: 20 }}>
                                <li>AWS tooling</li>
                                <li>Java</li>
                                <li>Ruby</li>
                            </ul>
                            <ul>
                                <li>React | React Native</li>
                                <li>Node | Express</li>
                                <li>DynamoDB | MongoDB | RethinkDB</li>
                            </ul>
                            <ul>
                                <li>JavaScript</li>
                                <li>HTML</li>
                                <li>CSS</li>
                            </ul>
                            <ul>
                                <li>C++</li>
                                <li>C</li>
                                <li>Python | Tensorflow</li>
                            </ul>
                            <ul>
                                <li>Maya | Arnold</li>
                                <li>Photoshop</li>
                                <li>Figma</li>
                            </ul>
                            <ul>
                                <li>Unity</li>
                                <li>C#</li>
                                <li>GLSL (OpenGl/WebGL)</li>
                            </ul>
                        </>
                    )}
                </ListWrapper>
            </Wrapper>
        );
    }
}

const Wrapper = styled(ScrollIntoView)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    box-sizing: border-box;
`;

const Header = styled.h1`
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: ${responsiveFontSize({ min: 32, max: 50 })};
`;

const Text = styled.p`
    text-align: left;
    margin-bottom: 0.5em;
`;

const ListWrapper = styled(FlexCentered)`
    justify-content: flex-start;
    width: 100%;
`;

const mapStateToProps = ({ sizes }) => {
    const { windowSize } = sizes;
    return { windowSize };
};

export default connect(mapStateToProps, {})(About);
