/**
 * ProjectPopup is a more detailed display of project data.
 */

import {
    React,
    Component,
    Proptypes,
    connect,
    styled,
    responsiveFontSize,
    FlexCentered,
    FlexCenteredColumn,
    isWidthUp,
} from '../../imports';

import { ScrollingImage } from '../common';

class ProjectPopup extends Component {
    timeoutId = null;
    state = { exited: true, imageLoading: true };
    bodyStyles = { height: null, overflow: null };

    componentWillUnmount = () => {
        this._cleanupTimeout();
        this._enableScroll();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.visible && !prevProps.visible) {
            this._disableScroll();
        } else if (!this.props.visible && prevProps.visible) {
            this._enableScroll();
        }

        if (this.props.visible) {
            if (this.timeoutId) {
                this._cleanupTimeout();
            }
            if (this.state.exited) {
                this.setState({ exited: false });
            }
        }
    };

    render() {
        const {
            project,
            visible,
            useBubbles,
            windowSize,
            theme,
            closeTransitionTime,
        } = this.props;
        const {
            title,
            subtitle,
            description,
            imgs,
            id,
            techstack,
            link,
        } = project || {
            imgs: [],
        };
        const { exited, imageLoading } = this.state;

        const open = visible && !exited && !imageLoading && !!imgs.length;
        const smallScreen = !isWidthUp('sm', windowSize.width);

        return (
            <>
                <Background
                    visible={open}
                    exited={exited}
                    onClick={this._close}
                    style={{ backgroundColor: theme.imgShadow }}
                />
                <Wrapper
                    closeTransitionTime={closeTransitionTime}
                    visible={open}
                    exited={exited}
                    style={{
                        backgroundColor: theme.popupBGSecondary,
                        boxShadow: `0px 0px 5px 3px ${theme.popupShadow}`,
                        width: smallScreen ? 340 : 507.5,
                        height: smallScreen ? 600 : 700,
                    }}
                >
                    <div
                        style={{
                            height: smallScreen ? '39%' : '50%',
                            width: '100%',
                        }}
                    >
                        <ScrollingImage
                            imgs={imgs}
                            id={id}
                            alt={title}
                            windowSize={windowSize}
                            scrollFont={theme.imgScrollFont}
                            scrollBG={theme.imgScrollBG}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            wrapperStyle={{
                                boxShadow: `0 0px 8px 8px ${theme.imgShadow}`,
                                backgroundColor: theme.imgShadow,
                            }}
                            onLoad={() => {
                                const wasLoading = this.state.imageLoading;
                                if (wasLoading && imgs.length)
                                    this.setState({ imageLoading: false });
                                return wasLoading;
                            }}
                        />
                    </div>
                    <DescriptionWrapper
                        style={{
                            backgroundColor: theme.popupBGTertiary,
                            height: smallScreen ? '61%' : '50%',
                        }}
                    >
                        <Title style={{ color: theme.font3 }}>{title}</Title>
                        <SubTitle style={{ color: theme.subtitle }}>
                            {subtitle}
                        </SubTitle>
                        <Description style={{ color: theme.font2 }}>
                            {description}
                        </Description>
                        {techstack && useBubbles && (
                            <BubbleWrapper>
                                {techstack.map((t, i) => (
                                    <Bubble
                                        key={t}
                                        style={{
                                            marginLeft: i === 0 ? 0 : 10,
                                        }}
                                        theme={theme}
                                    >
                                        {t}
                                    </Bubble>
                                ))}
                            </BubbleWrapper>
                        )}
                        {link && (
                            <Link
                                href={link}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ color: theme.link }}
                                hoverColor={theme.linkHover}
                            >
                                Find out more
                            </Link>
                        )}
                    </DescriptionWrapper>
                </Wrapper>
            </>
        );
    }

    _close = (e) => {
        e.stopPropagation();
        if (!this.timeoutIdt) {
            this.timeoutId = setTimeout(() => {
                this.props.onExit();
                this.setState({ exited: true, imageLoading: true });
                this._cleanupTimeout();
            }, this.props.closeTransitionTime);
            this.props.close();
        }
    };

    _disableScroll = () => {
        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';
    };

    _enableScroll = () => {
        document.body.style.height = '';
        document.body.style.overflow = '';
    };

    _cleanupTimeout = () => {
        clearInterval(this.timeoutId);
        this.timeoutId = null;
    };
}

const Background = styled(FlexCentered)`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: 0.5s opacity;
    opacity: ${({ visible }) => (visible ? 0.5 : 0)};
    z-index: ${({ exited }) => (exited ? -1 : 199)};
`;

const Wrapper = styled(FlexCenteredColumn)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0.2em;
    transition: ${({ closeTransitionTime }) => closeTransitionTime}ms linear
            transform,
        ${({ closeTransitionTime }) => closeTransitionTime}ms linear opacity;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transform: scale(${({ visible }) => (visible ? 1 : 0.8)});
    z-index: ${({ exited }) => (exited ? -1 : 200)};
    overflow: auto;
`;

const DescriptionWrapper = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    overflow: auto;
`;

const Title = styled.h1`
    margin: 0;
    margin-bottom: 0.1em;
    text-align: left;
    width: 100%;
    font-size: ${responsiveFontSize({
        min: 20,
        max: 28,
    })};
`;

const SubTitle = styled.h3`
    margin-top: 0.1em;
    margin-bottom: 0.2em;
    font-weight: bold;
    color: #afafaf;
    text-align: left;
    width: 100%;
    font-size: ${responsiveFontSize({
        min: 16,
        max: 22,
    })};
`;

const Description = styled.p`
    width: 100%;
    margin-bottom: 0;
    color: #afafaf;
    text-align: left;
    width: 100%;
    margin-bottom: 1em;
    font-size: ${responsiveFontSize({
        min: 12,
        max: 15,
    })};
`;

const Link = styled.a`
    text-align: left;
    margin-top: auto;
    width: fit-content;
    font-size: ${responsiveFontSize({
        min: 12,
        max: 16,
    })};
    &:hover {
        color: ${({ hoverColor }) => hoverColor};
    }
`;

const BubbleWrapper = styled(FlexCentered)`
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const Bubble = styled.div`
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: white;
    border: 2px solid #0f0f0f;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 20%;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
        transform: scale(0.95);
        background-color: ${({ theme }) => theme.backgroundTertiary};
    }
`;

ProjectPopup.propTypes = {
    project: Proptypes.object,
    windowSize: Proptypes.object,
    theme: Proptypes.object,

    onExit: Proptypes.func,
    close: Proptypes.func,

    visible: Proptypes.bool,
    useBubbles: Proptypes.bool,
    closeTransitionTime: Proptypes.number,
};

ProjectPopup.defaultProps = {
    project: {},
    windowSize: {},
    theme: {},
    onExit: () => {},
    close: () => {},
    visible: false,
    useBubbles: false,
    closeTransitionTime: 150,
};

const mapStateToProps = ({ sizes, themes }) => {
    const { windowSize } = sizes;
    const { theme } = themes;
    return { windowSize, theme };
};

export default connect(mapStateToProps, {})(ProjectPopup);
