// Optimized for React by me, but original credit to https://github.com/dwyl/learn-to-send-email-via-google-script-html-no-server

// get all data in form and return object
const getFormData = (form, inputs) => {
    const elements = inputs || form.elements;
    let honeypot;

    const fields = Object.keys(elements)
        .filter((k) => {
            if (elements[k].name === 'honeypot') {
                honeypot = elements[k].value;
                return false;
            }
            return true;
        })
        .map((k) => elements[k].name || console.error('Error in map'))
        .filter((item, i, self) => self.indexOf(item) === i && item);

    const formData = {};
    fields.forEach((name) => {
        const element = elements[name];

        // singular form elements just have one value
        formData[name] = element.value;

        // when our element has multiple items, get their values
        if (element.length) {
            let data = [];
            for (var i = 0; i < element.length; i++) {
                const item = element.item(i);
                if (item.checked || item.selected) {
                    data.push(item.value);
                }
            }
            formData[name] = data.join(', ');
        }
    });

    // add form-specific values into the data
    formData.formDataNameOrder = JSON.stringify(fields);
    formData.formGoogleSheetName = form.dataset.sheet || 'responses'; // default sheet name
    formData.formGoogleSend = form.dataset.email || ''; // no email by default

    return { data: formData, honeypot };
};

export const handleFormSubmit = (form, inputs, callback = () => {}) => {
    // handles form submit without any jquery
    const { data, honeypot } = getFormData(form, inputs);

    // If a honeypot field is filled, assume it was done so by a spam bot.
    if (honeypot) return false;

    const url = form.action;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    // xhr.withCredentials = true;
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = callback;
    // url encode form data for sending as post data
    var encoded = Object.keys(data)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
        .join('&');
    xhr.send(encoded);
};

export default {
    handleFormSubmit,
};
