/**
 * ScrollIntoView is a wrapper that fires some action when it's top has entered the viewport.
 * Using an offset pushes the threshold for when the action is fired farther down the viewport.
 */

import { React, Component, Proptypes } from '../../imports';

export default class ScrollIntoView extends Component {
    ref = React.createRef();

    componentDidMount = () => {
        this.checkIfInView();
        window.addEventListener('scroll', this.checkIfInView);
    };

    componentWillUnmount = () =>
        window.removeEventListener('scroll', this.checkIfInView);

    checkIfInView = () => {
        const { top, bottom } = this.ref.current?.getBoundingClientRect() || {};
        const {
            persist,
            offsetFromTop,
            onScrollIntoView,
            onScrollOutOfView,
        } = this.props;

        if (persist) {
            if (top < offsetFromTop && bottom > offsetFromTop) {
                onScrollIntoView();
            } else if (bottom < offsetFromTop) {
                onScrollOutOfView();
            }
        } else if (top < offsetFromTop) {
            onScrollIntoView();
            window.removeEventListener('scroll', this.checkIfInView);
        }
    };

    render() {
        const {
            onScrollIntoView,
            onScrollOutOfView,
            offsetFromTop,
            persist,
            ...rest
        } = this.props;
        return <div {...rest} ref={this.ref} />;
    }
}

ScrollIntoView.propTypes = {
    onScrollIntoView: Proptypes.func,
    onScrollOutOfView: Proptypes.func,
    offsetFromTop: Proptypes.number,
    persist: Proptypes.bool,
};

ScrollIntoView.defaultProps = {
    onScrollIntoView: () => {},
    offsetFromTop: 0,
    persist: false,
};
