/**
 * ContactForm uses FunForm and a paper airplane icon to implement an animated form.
 */

import {
    React,
    Component,
    styled,
    responsiveFontSize,
    handleFormSubmit,
    isWidthDown,
    FlexCenteredColumn,
} from '../../imports';
import FunForm from '../common/FunForm';
import { Airplane } from '../../assets/icons';

const contactInputs = [
    {
        type: 'text',
        name: 'name',
        placeholder: 'Your Name',
        maxLength: 256,
    },
    {
        type: 'email',
        name: 'email',
        placeholder: 'Your Email',
        maxLength: 256,
        validateOnType: false,
    },
    {
        type: 'text',
        name: 'subject',
        placeholder: 'Subject',
        maxLength: 256,
    },
    {
        as: 'textarea',
        type: 'text',
        name: 'message',
        placeholder:
            "Your message, e.g.: Hey! I loved what I saw on your site - I've got this great idea...",
        style: { resize: 'none', flex: 1, width: '100%', minHeight: 200 },
        maxLength: Math.pow(2, 10),
    },
];

const validateInput = (inputs = []) => {
    const errors = {};
    let errCount = 0;
    inputs.forEach(({ name, input }) => {
        switch (name) {
            case 'name':
            case 'subject':
            case 'message':
                if (input.length === 0) {
                    errors[name] = 'This field is required';
                    errCount++;
                }
                break;
            case 'email':
                if (input.length === 0) {
                    errors[name] = 'This field is required';
                    errCount++;
                } else if (!input.match(/@.+\../)) {
                    errors[name] = 'Please enter a valid email';
                    errCount++;
                }
                break;
            default:
                return true;
        }
    });
    return errCount > 0 ? errors : null;
};

const easing = 'cubic-bezier(.45,-0.39,.67,.99)';

export default class ContactForm extends Component {
    state = { submitting: false, submitted: false };

    airplane = React.createRef();
    wrapper = React.createRef();
    anim = null;

    componentWillUnmount = () => {
        if (this.anim?.cancel) {
            this.anim.cancel();
        }
    };

    flyAirplane = (callback = () => {}) => {
        this.anim = this.airplane.current.animate(
            [
                {
                    transform: 'translate(0px, 0px) rotate3d(1, -1, 0, 0deg)',
                },
                {
                    transform:
                        'translate(-40px, 20px) rotate3d(1, -1, 0, -30deg)',
                },
            ],
            {
                easing: 'ease-out',
                duration: 500,
                fill: 'forwards',
            }
        );
        this.anim.pause();
        this.anim.onfinish = () => {
            this.anim = this.airplane.current.animate(
                [
                    {
                        transform:
                            'translate(-40px, 20px) scale(1) rotate3d(1, -1, 0, -30deg)',
                    },
                    {
                        transform:
                            'translate(560px, -580px) scale(0) rotate3d(1, -1, 0, 90deg)',
                    },
                ],
                {
                    easing: 'ease-in',
                    duration: 500,
                    fill: 'forwards',
                }
            );
            this.anim.onfinish = callback;
        };
        this.anim.play();
    };

    showThankYouMessage = () => {
        this.anim = this.wrapper.current.animate(
            [
                {
                    transform: 'scale(1)',
                    opacity: 1,
                },
                {
                    transform: 'scale(0.5)',
                    opacity: 0,
                },
            ],
            {
                easing,
                duration: 200,
            }
        );
        this.anim.pause();
        this.anim.onfinish = () => {
            this.setState({ submitting: false, submitted: true }, () => {
                this.anim = this.wrapper.current.animate(
                    [
                        {
                            transform: 'scale(0.5)',
                            opacity: 0,
                        },
                        {
                            transform: 'scale(1)',
                            opacity: 1,
                        },
                    ],
                    {
                        easing,
                        duration: 200,
                    }
                );
                this.anim.pause();
                this.anim.onfinish = () => {};
                this.anim.play();
            });
        };
        this.anim.play();
    };

    render() {
        const { theme, focused, style, windowSize } = this.props;
        const { submitting, submitted } = this.state;
        const smallSceen = isWidthDown('sm', windowSize.width);
        const appearStyle = {
            transform: focused ? 'none' : 'translateX(-400px)',
            opacity: focused ? 1 : 0,
            transition: '0.5s opacity, 0.5s transform',
        };

        return (
            <FlexCenteredColumn
                ref={this.wrapper}
                style={{
                    flex: 1,
                    justifyContent: submitted ? 'center' : 'flex-start',
                }}
            >
                {submitted ? (
                    <>
                        <Header
                            style={{
                                color: theme.header,
                                marginTop: 0,
                            }}
                        >
                            Thanks for reaching out!
                        </Header>
                        <SubHeader
                            style={{
                                color: theme.subtitle,
                            }}
                        >
                            You'll hear back from me as soon as possible.
                        </SubHeader>
                    </>
                ) : (
                    <>
                        <Header
                            style={{
                                ...appearStyle,
                                color: theme.header,
                                marginTop: '15vh',
                            }}
                        >
                            Wanna chat?
                        </Header>
                        <SubHeader
                            style={{
                                ...appearStyle,
                                transitionDelay: '0.25s',
                                color: theme.subtitle,
                            }}
                        >
                            Let's get in touch!
                        </SubHeader>
                        <div ref={this.airplane}>
                            <Airplane
                                style={{
                                    ...appearStyle,
                                    transform: focused
                                        ? 'none'
                                        : 'translate(-40px, 40px)',
                                    transitionDelay: '0.75s',
                                    height: smallSceen ? 100 : 150,
                                    width: smallSceen ? 100 : 150,
                                    margin: '50px 0',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                ...appearStyle,
                                transitionDelay: '0.5s',
                            }}
                        >
                            <FunForm
                                inputs={contactInputs}
                                onSubmit={({ form, inputs }) => {
                                    const submitForm = () => {
                                        this.setState(
                                            { submitting: true },
                                            () => {
                                                handleFormSubmit(
                                                    form,
                                                    inputs,
                                                    this.showThankYouMessage
                                                );
                                            }
                                        );
                                    };
                                    this.flyAirplane(submitForm);
                                }}
                                submitting={submitting}
                                validate={validateInput}
                                theme={theme}
                                style={style}
                                easing={easing}
                            />
                        </div>
                    </>
                )}
            </FlexCenteredColumn>
        );
    }
}

const Header = styled.h1`
    margin-bottom: 10px;
    font-size: ${responsiveFontSize({ min: 32, max: 50 })};
`;

const SubHeader = styled.h1`
    margin: 0;
    font-size: ${responsiveFontSize({ min: 18, max: 22 })};
    font-weight: 500;
`;
