import { React, styled, randInt } from '../../imports';

const getNewPath = ({
    min_x = 0,
    min_y = 200,
    min_x_range = 200,
    min_y_range = 50,
    max_x = 1200,
    max_y = 650,
    max_x_range = 200,
    max_y_range = 75,
    min_duration = 1500,
    max_duration = 2000,
} = {}) => {
    const start_x = randInt(min_x, min_x + min_x_range);
    const start_y = randInt(min_y, min_y + min_y_range);
    return {
        start_x,
        end_x: randInt(max_x - max_x_range, max_x),
        start_y,
        end_y: randInt(max_y - max_y_range, max_y),
        duration: randInt(min_duration, max_duration),
    };
};

const Star = ({ stop, color, probabilityOfStarFlying = 16 }) => {
    const timeoutId = React.useRef({ id: null }).current;
    const ref = React.useRef();
    const [path, setPath] = React.useState({
        ...getNewPath(),
        firstTime: true,
    });
    React.useEffect(() => {
        if (stop) {
            clearTimeout(timeoutId.id);
            timeoutId.id = null;
        } else if (timeoutId.id === null) {
            timeoutId.id = setTimeout(() => {
                timeoutId.id = null;
                setPath(getNewPath());
            }, 1000);
            return () => clearTimeout(timeoutId.id);
        }
    }, [stop, timeoutId.id]);
    React.useLayoutEffect(() => {
        const { start_x, end_x, start_y, end_y } = path;
        const a = end_x - start_x;
        const b = end_y - start_y;
        const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
        const newRotate = Math.asin(b / c);
        if (
            ref.current &&
            randInt(0, path.firstTime ? 2 : probabilityOfStarFlying) === 0
        ) {
            ref.current.animate(
                [
                    {
                        transform: `translate(${start_x}px, ${start_y}px) rotate(${newRotate}rad) scaleX(1)`,
                        opacity: 1,
                    },
                    {
                        transform: `translate(${end_x}px, ${end_y}px) rotate(${newRotate}rad) scaleX(0)`,
                        opacity: 0,
                    },
                ],
                {
                    duration: path.duration,
                    easing: 'linear',
                }
            );
        }
        timeoutId.id = setTimeout(() => {
            timeoutId.id = null;
            setPath(getNewPath());
        }, path.duration);
        return () => clearTimeout(timeoutId.id);
    }, [path, probabilityOfStarFlying, timeoutId.id]);
    return (
        <svg
            width='87'
            height='29'
            viewBox='0 0 87 29'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            ref={ref}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 30,
                height: 30,
                opacity: 0,
            }}
        >
            <g filter='url(#filter0_f)'>
                <path d='M8 8L69 10.5536V18.4464L8 21V8Z' fill='white' />
            </g>
            <g filter='url(#filter1_f)'>
                <ellipse cx='73' cy='14' rx='13' ry='11' fill='white' />
            </g>
            <defs>
                <filter
                    id='filter0_f'
                    x='0'
                    y='0'
                    width='77'
                    height='29'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feGaussianBlur
                        stdDeviation='4'
                        result='effect1_foregroundBlur'
                    />
                </filter>
                <filter
                    id='filter1_f'
                    x='59'
                    y='2'
                    width='28'
                    height='24'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='BackgroundImageFix'
                        result='shape'
                    />
                    <feGaussianBlur
                        stdDeviation='0.5'
                        result='effect1_foregroundBlur'
                    />
                </filter>
            </defs>
        </svg>
    );
};

const ShootingStars = ({ stop, numStars = 8, color }) => {
    let stars = [];
    for (let i = 0; i < numStars; i++) {
        stars.push(<Star key={i} stop={stop} color={color} />);
    }
    return <Canvas>{stars}</Canvas>;
};

const Canvas = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export default ShootingStars;
