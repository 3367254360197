import {
    React,
    Component,
    Proptypes,
    styled,
    css,
    keyframes,
    responsiveFontSize,
    FlexCentered,
    Tooltip,
} from '../../imports';
import submitIcon from '../../assets/img/loading.png';
import {
    ArrowBack,
    ArrowForward,
    ArrowSend,
    Exclamation,
} from '../../assets/icons';

const animate = ({ el, keyframes, options, callback }) => {
    const anim = el.animate(keyframes, {
        duration: 200,
        iterations: 1,
        direction: 'normal',
        ...options,
    });
    anim.pause();
    anim.onfinish = callback;
    anim.play();
    return anim;
};
const directions = { NEXT: 'NEXT', PREVIOUS: 'PREVIOUS' };

// Responses found here: https://docs.google.com/spreadsheets/d/1tjpXWKeNR__uwVhLISPIEu4U2WDQ3GlwnzOvFxgb248/edit#gid=0
// Credit: https://github.com/dwyl/learn-to-send-email-via-google-script-html-no-server
const action =
    'https://script.google.com/macros/s/AKfycbxmEZEktSVw8LNp4o73FiLzz_qZds0wq3rnEKAySeS9LvWBHHEv/exec';

export default class FunForm extends Component {
    state = {
        ...this.props.inputs.reduce(
            (acc, { name }) => ({ ...acc, [name]: '' }),
            {}
        ),
        currentInput: 0,
        errors: null,
        animating: false,
    };

    formRef = React.createRef();
    inputRef = React.createRef();
    animation = null;

    componentDidMount = () => {
        window.addEventListener('keypress', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const { animating, currentInput } = this.state;
                const { inputs, submitting } = this.props;
                if (!animating && !submitting) {
                    if (currentInput >= inputs.length - 1) {
                        this._handleSubmit();
                    } else {
                        this._nextInput(inputs[currentInput].name);
                    }
                }
                return false;
            }
        });
    };

    componentDidUpdate = (prevProps) => {
        if (!prevProps.focused && this.props.focused && this.inputRef) {
            this.inputRef.current.focus();
        }
    };

    componentWillUnmount = () => {
        if (this.animation) {
            this.animation.cancel();
        }
    };

    render() {
        const { theme, inputs, iconSize, submitting } = this.props;
        const { animating, currentInput } = this.state;
        const errors = this.state.errors || {};
        const {
            as,
            maxLength,
            type,
            name,
            placeholder,
            style,
            required,
            validateOnType,
        } = inputs[currentInput];

        const backIconDisabled = animating || submitting || currentInput === 0;
        const forwardIconDisabled = animating || submitting;
        const iconsOnBottom = as === 'textarea';

        const backButton = (
            <BackIcon
                id='back'
                size={iconSize}
                disabled={backIconDisabled}
                fill={backIconDisabled ? 'transparent' : theme.contactIcon}
                onClick={() => {
                    if (!backIconDisabled)
                        this._inputChange(directions.PREVIOUS);
                }}
            />
        );

        return (
            <Form
                ref={this.formRef}
                method='POST'
                onSubmit={this._handleSubmit}
                action={action}
                style={{
                    ...this.props.style,
                    flexDirection: iconsOnBottom ? 'column' : 'row',
                    flex: iconsOnBottom ? 0.8 : '',
                    backgroundColor: theme.inputBG,
                }}
            >
                {submitting && (
                    <SubmittingIcon alt='submitting' src={submitIcon} />
                )}
                {!iconsOnBottom && currentInput !== 0 && (
                    <FlexCentered style={{ backgroundColor: theme.inputBG }}>
                        {backButton}
                    </FlexCentered>
                )}
                <Input
                    ref={this.inputRef}
                    iconSize={iconSize}
                    disabled={animating || submitting}
                    as={as}
                    maxLength={maxLength}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    style={{ ...style, backgroundColor: theme.inputBG }}
                    value={this.state[name]}
                    onChange={(e) => {
                        let newState = { [name]: e.target.value };
                        if (validateOnType !== false)
                            newState.errors = this._validate({
                                inputs: {
                                    [name]: e.target.value,
                                },
                                names: [name],
                            });
                        this.setState(newState);
                    }}
                />
                <FlexCentered
                    style={{
                        backgroundColor: theme.inputBG,
                        ...(iconsOnBottom
                            ? {
                                  width: '100%',
                                  justifyContent:
                                      currentInput === 0
                                          ? 'flex-start'
                                          : 'space-between',
                              }
                            : {}),
                    }}
                >
                    {iconsOnBottom && currentInput !== 0 && backButton}
                    <IconTooltip
                        title={errors[name] || ''}
                        aria-label={errors[name]}
                        open={!!errors[name]}
                        size={iconSize}
                        disabled={forwardIconDisabled}
                        nohover={errors[name]}
                        fill={
                            forwardIconDisabled
                                ? 'transparent'
                                : errors[name]
                                ? theme.contactIconBG
                                : theme.contactIcon
                        }
                        arrow
                    >
                        {errors[name] ? (
                            <ErrorIcon
                                id='error'
                                onClick={() => this._nextInput(name)}
                                style={{
                                    backgroundColor: errors[name]
                                        ? theme.error
                                        : 'transparent',
                                }}
                            />
                        ) : currentInput === inputs.length - 1 ? (
                            <SubmitIcon
                                id='submit'
                                type='submit'
                                onClick={this._handleSubmit}
                            />
                        ) : (
                            <ForwardIcon
                                id='next'
                                onClick={() => this._nextInput(name)}
                            />
                        )}
                    </IconTooltip>
                </FlexCentered>
            </Form>
        );
    }

    _inputChange = (direction) => {
        if (this.state.animating) return;
        const animationRunning = (anim) =>
            anim && anim.playState !== 'finished';
        this.setState({ animating: true }, () => {
            if (!animationRunning(this.animation)) {
                const easing = this.props.easing;
                const inputStep =
                    direction === directions.NEXT
                        ? 1
                        : direction === directions.PREVIOUS
                        ? -1
                        : 0;
                this.animation = animate({
                    el: this.formRef.current,
                    keyframes: [
                        { transform: 'scale(1)', opacity: 1 },
                        { transform: 'scale(0)', opacity: 0 },
                    ],
                    options: { easing },
                    callback: () => {
                        if (!animationRunning(this.animation)) {
                            this.setState(
                                ({ currentInput }) => ({
                                    currentInput: currentInput + inputStep,
                                }),
                                () => {
                                    this.animation = animate({
                                        el: this.formRef.current,
                                        keyframes: [
                                            {
                                                transform: 'scale(0)',
                                                opacity: 0,
                                            },
                                            {
                                                transform: 'scale(1)',
                                                opacity: 1,
                                            },
                                        ],
                                        options: { easing },
                                        callback: () => {
                                            this.setState(
                                                { animating: false },
                                                () => {
                                                    this.inputRef.current.focus();
                                                }
                                            );
                                        },
                                    });
                                }
                            );
                        }
                    },
                });
            }
        });
    };

    _validate = ({ inputs, names }) => {
        const errors = this.props.validate(
            names.map((name) => ({
                name,
                input: inputs[name],
            }))
        );
        return errors;
    };

    _nextInput = (currInputName) => {
        const errors = this._validate({
            inputs: this.state,
            names: [currInputName],
        });
        this.setState({ errors }, () => {
            if (!errors || !errors[currInputName]) {
                this._inputChange(directions.NEXT);
            }
        });
    };

    _handleSubmit = () => {
        const errors = this._validate({
            inputs: this.state,
            names: this.props.inputs.map(({ name }) => name),
        });
        if (errors) {
            this.setState({ errors });
        } else {
            this.props.onSubmit({
                inputs: this.props.inputs.reduce(
                    (acc, { name }) => ({
                        ...acc,
                        [name]: { name, value: this.state[name] },
                    }),
                    {}
                ),
                form: this.formRef.current,
            });
        }
    };
}

// Main components

const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Input = styled.input`
    box-sizing: border-box;
    font-size: ${responsiveFontSize({ min: 12, max: 18 })};
    width: 100%;
    padding: 6px;
    border: none;
    transition: 0.5s transform, 0.5s opacity;
    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: lightgray;
    }
`;

// Icon components

const iconStyles = css`
    box-sizing: border-box;
    background-clip: content-box;
    width: ${({ size }) => size || '40px'};
    height: ${({ size }) => size || '40px'};
    max-height: 100%;
    padding: 4px;
    color: ${({ fill }) => fill};
    background-color: ${({ background }) => background};
    transition: ${({ disabled }) => (disabled ? 0.1 : 0.5)}s;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    &:hover {
        transform: scale(
            ${({ disabled, nohover }) => (disabled || nohover ? 1 : 0.85)}
        );
    }
`;

const IconTooltip = styled(Tooltip)`
    ${iconStyles}
`;

const ForwardIcon = styled(ArrowForward)``;

const BackIcon = styled(ArrowBack)`
    ${iconStyles}
`;

const SubmitIcon = styled(ArrowSend)``;

const spin = keyframes`
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
`;

const SubmittingIcon = styled.img`
    position: absolute;
    animation: ${spin} 0.5s ease-out infinite;
`;

const bounce = keyframes`
    0% { transform: translateY(-0.3vh) }
    50% { transform: translateY(0.3vh) }
    100% { transform: translateY(0px) }
`;

const ErrorIcon = styled(Exclamation)`
    animation: ${bounce} 0.2s ease-out;
    width: ${({ size }) => size || '40px'};
    height: ${({ size }) => size || '40px'};
    border-radius: 50%;
`;

FunForm.propTypes = {
    inputs: Proptypes.array.isRequired,
    onSubmit: Proptypes.func,
    easing: Proptypes.string,
    theme: Proptypes.object,
    focused: Proptypes.bool,
    iconSize: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
    thankYouMessage: Proptypes.oneOfType([
        Proptypes.object,
        Proptypes.func,
        Proptypes.string,
    ]),
};

FunForm.defaultProps = {
    inputs: [],
    easing: 'ease-out',
    theme: {
        header: 'black',
        contactIcon: 'black',
        contactIconBG: 'gray',
        iconBG: 'white',
        error: 'red',
    },
    focused: false,
    iconSize: responsiveFontSize({ min: 27, max: 30 }),
    onSubmit: ({ callback }) => {
        callback();
    },
};
