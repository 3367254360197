// ------------------------------------ TYPES ------------------------------------
export const types = {
    SET_POPOVER_OPEN: 'SET_POPOVER_OPEN',
    SET_POPOVER_PROJECT: 'SET_POPOVER_PROJECT',
    CLEAR_POPOVER_PROJECT: 'CLEAR_POPOVER_PROJECT',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    popoverVisible: false,
    projectToShow: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_POPOVER_OPEN:
            return { ...state, popoverVisible: action.payload };
        case types.SET_POPOVER_PROJECT:
            return {
                ...state,
                popoverVisible: true,
                projectToShow: action.payload,
            };
        case types.CLEAR_POPOVER_PROJECT:
            return {
                ...state,
                popoverVisible: false,
                projectToShow: null,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const updatePopoverVisible = (popoverVisible) => ({
    type: types.SET_POPOVER_OPEN,
    payload: popoverVisible,
});

export const setProjectToShow = (projectToShow) => ({
    type: types.SET_POPOVER_PROJECT,
    payload: projectToShow,
});

export const clearProjectToShow = () => ({
    type: types.CLEAR_POPOVER_PROJECT,
    payload: true,
});
