/**
 * The ChangingImage component switches between 2 images on hover.
 */

import { React, Component, styled, Proptypes } from '../../imports';

export default class ChangingImage extends Component {
    state = { currentImage: 1 };

    render() {
        const { props, state } = this;
        const { style, img1, img2, title } = props;
        const { currentImage } = state;

        return (
            <div
                style={{ ...style, position: 'relative' }}
                onMouseEnter={() => this.setState({ currentImage: 2 })}
                onMouseLeave={() => this.setState({ currentImage: 1 })}
            >
                <Image
                    src={img1}
                    alt={title}
                    style={{ opacity: 0, position: 'static' }}
                />
                <Image
                    {...this.props}
                    src={img1}
                    alt={title}
                    style={{ opacity: currentImage === 1 ? 1 : 0 }}
                />
                <Image
                    src={img2}
                    alt={title}
                    style={{ opacity: currentImage === 2 ? 1 : 0 }}
                />
            </div>
        );
    }
}

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    border-radius: 5px;
    transition: 0.5s opacity;
`;

ChangingImage.propTypes = {
    style: Proptypes.object,
    img1: Proptypes.string,
    img2: Proptypes.string,
};

ChangingImage.defaultProps = {
    style: {},
    img1: '',
    img2: '',
};
