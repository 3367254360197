/*
 *   imports.js allows different files to easily grab common imports from one file
 */

export { default as React } from 'react';
export { Component } from 'react';
export { BrowserRouter, Switch, Route, Link, Redirect } from 'react-router-dom';
export { default as Proptypes } from 'prop-types';
export { default as styled } from 'styled-components';
export { css, keyframes } from 'styled-components';
export { connect } from 'react-redux';
export { Skeleton } from '@material-ui/lab';
export { Tooltip } from '@material-ui/core';

export * from './services/common-styles';

export * from './services/common-functions';

export * from './services/animation-funcs';

export * from './services/animated-list-funcs';

export * from './services/form-submission-handler';

export * from './services/project-constants';

export const theme1 = {
    tan: '#edc7b7',
    white: '#eee2dc',
    navy: '#123c69',
    lightnavy: '#224c79',
    ruby: '#ac3b61',
    gray: '#bab2b5',
    darkred: '#7c2031',
    black: '#0f0f0f',
    darkerblack: '#0a0a0a',
    lightgray: '#afafaf',
    purewhite: '#ffffff',
    pureblack: '#000000',
};

export const theme = {
    sample1: theme1.black,
    sample2: theme1.navy,
    backgroundPrimary: theme1.black,
    backgroundSecondary: theme1.navy,
    backgroundTertiary: theme1.gray,
    skeleton: theme1.white,

    header: theme1.white,
    subtitle: theme1.lightgray,
    font: theme1.gray,
    font1: theme1.lightgray,
    font2: theme1.black,
    font3: theme1.darkerblack,
    font4: theme1.gray,
    link: theme1.ruby,
    linkHover: theme1.tan,
    inputBG: theme1.purewhite,

    imgShadow: theme1.darkerblack,
    inputSelectedShadow: theme1.lightnavy,

    popupShadow: theme1.darkerblack,
    popupBGSecondary: theme1.purewhite,
    popupBGTertiary: 'rgba(18, 60, 105, 0.1)',

    optionFont: theme1.white,
    optionFontSelected: theme1.navy,

    imgScrollFont: theme1.lightgray,
    imgScrollBG: theme1.purewhite,

    contactIcon: theme1.black,
    contactIconBG: theme1.purewhite,
    error: theme1.ruby,
};

export const themeLight = {
    sample1: theme1.white,
    sample2: theme1.navy,
    backgroundPrimary: theme1.navy,
    backgroundSecondary: theme1.white,
    backgroundTertiary: theme1.lightgray,
    skeleton: theme1.black,

    header: theme1.purewhite,
    subtitle: theme1.lightgray,
    font: theme1.lightgray,
    font1: theme1.lightgray,
    font2: theme1.black,
    font3: theme1.darkerblack,
    font4: theme1.gray,
    link: theme1.ruby,
    linkHover: theme1.tan,
    inputBG: theme1.purewhite,

    imgShadow: theme1.darkerblack,
    inputSelectedShadow: theme1.lightnavy,

    popupShadow: theme1.purewhite,
    popupBGSecondary: theme1.purewhite,
    popupBGTertiary: 'rgba(18, 60, 105, 0.1)',

    optionFont: theme1.purewhite,
    optionFontSelected: theme1.darkerblack,

    imgScrollFont: theme1.lightgray,
    imgScrollBG: 'black',

    contactIcon: theme1.black,
    contactIconBG: theme1.purewhite,
    error: theme1.ruby,
};

export const themes = [theme, themeLight];
