export const sizesXlToXs = ['xl', 'lg', 'md', 'sm', 'xs'];
export const sizesXsToXl = ['xs', 'sm', 'md', 'lg', 'xl'];
export const defaultSizeWidthMappings = {
    'xs': 0,
    'sm': 600,
    'md': 960,
    'lg': 1280,
    'xl': 1920,
};

export const findSmallest = (obj) => obj[sizesXsToXl.find((sz) => obj[sz])];

export const isWidthUp = (
    size,
    width,
    sizeWidthMappings = defaultSizeWidthMappings
) => width > sizeWidthMappings[size];

export const isWidthDown = (
    size,
    width,
    sizeWidthMappings = defaultSizeWidthMappings
) => width < sizeWidthMappings[size];

export const getItemWidth = (containerWidth, itemsPerRow, itemSpacing = 1) =>
    (containerWidth - itemSpacing * (itemsPerRow - 1)) / itemsPerRow;

export const getValForScreenWidth = (
    itemsPerRowForGivenWidth,
    screenWidth,
    def = 1
) => {
    const matchingSizes = sizesXlToXs.filter(
        (sz) => itemsPerRowForGivenWidth[sz] && isWidthUp(sz, screenWidth)
    );

    return matchingSizes.length === 0
        ? findSmallest(itemsPerRowForGivenWidth, screenWidth) || def
        : itemsPerRowForGivenWidth[matchingSizes[0]];
};
