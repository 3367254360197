/**
 * OptionBar displays a list of option bubbles that do something when clicked.
 * Generally, options will take update the state of OptionBar's parent in order to change how data is displayed.
 */

import {
    React,
    Proptypes,
    styled,
    responsiveFontSize,
    FlexCentered,
} from '../../imports';

const OptionBar = (props) => {
    const ref = React.useRef();
    const {
        selectedOption,
        onChangeOption,
        style,
        font,
        fontSelected,
        optionLength,
        optionSpacing,
    } = props;
    const options = Array.isArray(props.options)
        ? props.options
        : Object.values(props.options);

    return (
        <OptionsWrapper
            ref={ref}
            style={{
                ...style,
                justifyContent: optionSpacing ? 'center' : 'space-around',
            }}
        >
            {options.map((option, i) => (
                <Option
                    id={`optionbar-option-${option}`}
                    key={`optionbar-option-${option}`}
                    onClick={() => onChangeOption(option)}
                    selected={selectedOption === option}
                    font={font}
                    fontSelected={fontSelected}
                    style={{
                        width: optionLength,
                        marginLeft:
                            i === 0 || !optionSpacing ? 0 : optionSpacing,
                    }}
                >
                    {option}
                </Option>
            ))}
        </OptionsWrapper>
    );
};

const OptionsWrapper = styled(FlexCentered)`
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    white-space: nowrap;
    flex-wrap: wrap;
    transition: 0.5s;
    overflow: auto;
`;

const Option = styled.div`
    border: 1px solid ${({ font }) => font};
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;

    text-align: center;
    font-weight: bold;
    font-size: ${responsiveFontSize({ min: 10, max: 16 })};

    cursor: pointer;
    transition: 0.5s;

    color: ${({ selected, font, fontSelected }) =>
        selected ? fontSelected : font};
    background-color: ${({ selected, font }) =>
        selected ? font : 'transparent'};
    &:hover {
        transform: scale(0.9);
`;

OptionBar.propTypes = {
    options: Proptypes.oneOfType([Proptypes.array, Proptypes.object])
        .isRequired,
    selectedOption: Proptypes.string,
    onChangeOption: Proptypes.func,
    style: Proptypes.object,
    font: Proptypes.string,
    fontSelected: Proptypes.string,
    optionLength: Proptypes.number,
    optionSpacing: Proptypes.number,
};

OptionBar.defaultProps = {
    options: [],
    selectedOption: '',
    onChangeOption: () => {},
    style: {},
    font: '',
    fontSelected: '',
    optionLength: 100,
};

export default OptionBar;
