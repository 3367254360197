/**
 * Carousel presents items 3 at a time.
 * When the left is clicked, it slides the other items out of focus to the right and takes the center
 * When the right is clicked, it slides the other items out of focus to the left and takes the center
 * Items on the edge wrap as they're pushed.
 * The size of the carousel is dependent on the size of the first item.
 */

import { React, Proptypes, styled } from '../../imports';

const Carousel = ({ items, style }) => {
    const [itemNum, setItemNum] = React.useState(0);
    const carouselWidth = style.width || 200;
    const renderedItems = items.map(({ href, onClick, component }, i) => {
        const selectedItem = i === itemNum;
        const firstItem = i === 0;
        const lastItem = i === items.length - 1;
        const leftOfSelected =
            (lastItem && itemNum === 0) || i - itemNum === -1;
        const rightOfSelected =
            (firstItem && itemNum === items.length - 1) || i - itemNum === 1;

        const onItemClick = (e) => {
            if (!selectedItem) e.preventDefault();

            if (selectedItem && (component.props?.onClick || onClick)) {
                onClick();
            } else if (leftOfSelected) {
                setItemNum(itemNum === 0 ? items.length - 1 : itemNum - 1);
            } else if (rightOfSelected) {
                setItemNum(itemNum === items.length - 1 ? 0 : itemNum + 1);
            }
        };
        const itemStyle = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: selectedItem
                ? 1
                : leftOfSelected || rightOfSelected
                ? 0.5
                : 0,
            zIndex: selectedItem
                ? 3
                : leftOfSelected || rightOfSelected
                ? 2
                : 1,
            transform: selectedItem
                ? 'none'
                : leftOfSelected
                ? `translateX(-${carouselWidth * 0.4}px) scale(0.75)`
                : rightOfSelected
                ? `translateX(${carouselWidth * 0.4}px) scale(0.75)`
                : `translateX(${(i > itemNum ? 1 : -1) * 200}) scale(0.75)`,
            transition: '0.5s transform, 0.5s opacity',
            cursor: 'pointer',
        };

        if (href) {
            return (
                <Link
                    key={i}
                    href={selectedItem ? component.props?.href || href : ''}
                    rel='noopener noreferrer'
                    target='_blank'
                    onClick={onItemClick}
                    style={itemStyle}
                >
                    {component}
                </Link>
            );
        } else {
            return (
                <div key={i} onClick={onItemClick} style={itemStyle}>
                    {component}
                </div>
            );
        }
    });
    return (
        <div style={{ ...style, position: 'relative' }}>
            <div style={{ position: 'static', opacity: 0 }}>
                {items[0].component}
            </div>
            {renderedItems}
        </div>
    );
};

const Link = styled.a`
    &::before {
        content: none;
    }
`;

Carousel.propTypes = {
    items: Proptypes.array.isRequired,
    style: Proptypes.object.isRequired,
};

Carousel.defaultProps = {
    items: [],
    style: {},
};

export default Carousel;
