/**
 * Home contains most of the top level logic, and organizes the screens.
 */

import {
    React,
    Component,
    connect,
    styled,
    scroll,
    FlexCenteredColumn,
    Section,
    isWidthDown,
} from '../imports';

import { Welcome, About, Portfolio, Archive, Contact } from './screens';
import { Footer, GlowingArrow, Header } from './common';
import { ProjectPopup } from './project';
import {
    Animation,
    // ShootingStars
} from './fun';
import { clearProjectToShow, updatePopoverVisible } from '../ducks/PopoverDuck';

import { MdEmail } from 'react-icons/md';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const sections = {
    WELCOME: 'Home',
    ABOUT: 'About',
    PORTFOLIO: 'Featured Projects',
    // ARCHIVE: 'The Archive',
    CONTACT: 'Contact',
};

const links = [
    { href: 'https://www.linkedin.com/in/gkupsaw/', icon: FaLinkedin },
    { href: 'mailto:gkupsaw@gmail.com', icon: MdEmail },
    { href: 'https://github.com/gkupsaw', icon: FaGithub },
];
const copyrightMessageSplit = 'SPLIT';
const copyrightMessage = `Designed and built by Griffin Kupsaw${copyrightMessageSplit}(with xtra special thanks to Cherilyn Tan)\n© 2020`;

class Home extends Component {
    state = { currentSection: sections.WELCOME, renderEverything: true };
    sectionRefs = Object.values(sections).reduce(
        (acc, v) => ({ ...acc, [v]: React.createRef() }),
        {}
    );
    timeoutId = null;

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
        requestAnimationFrame(() => {
            this.setState({
                currentSection: this.findCurrentSection() || sections.ABOUT,
            });
        });
    };

    componentWillUnmount = () => {
        clearTimeout(this.timeoutId);
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        const { sectionRefs } = this;
        const { currentSection } = this.state;

        if (!currentSection || !sectionRefs[currentSection]) return;

        const screenCenter = window.innerHeight / 2;
        const currentSectionDims = sectionRefs[
            currentSection
        ].current.getBoundingClientRect();
        const currentSectionTop = currentSectionDims.top;
        const currentSectionBottom = currentSectionDims.bottom;

        if (
            screenCenter > currentSectionBottom &&
            currentSection !== sections.CONTACT
        ) {
            this.setState((prevState) => ({
                currentSection: this.getNextSection(prevState.currentSection),
            }));
        } else if (
            screenCenter < currentSectionTop &&
            currentSection !== sections.WELCOME
        ) {
            this.setState((prevState) => ({
                currentSection: this.getNextSection(
                    prevState.currentSection,
                    true
                ),
            }));
        }
    };

    changeSection = (nextSection) => {
        if (!this.state.renderEverything)
            this.setState({ renderEverything: true }, () =>
                scroll(this.sectionRefs[sections.PORTFOLIO])
            );
        else scroll(this.sectionRefs[nextSection]);
    };

    getNextSection = (currSection, previous = false) => {
        switch (currSection) {
            case sections.WELCOME:
                return previous ? sections.CONTACT : sections.ABOUT;
            case sections.ABOUT:
                return previous ? sections.WELCOME : sections.PORTFOLIO;
            case sections.PORTFOLIO:
                return previous ? sections.ABOUT : sections.CONTACT;
            // case sections.ARCHIVE:
            //     return previous ? sections.PORTFOLIO : sections.CONTACT;
            case sections.CONTACT:
                return previous ? sections.PORTFOLIO : sections.WELCOME;
            default:
                return previous ? sections.PORTFOLIO : sections.WELCOME;
        }
    };

    findCurrentSection = () => {
        return Object.values(sections).find(
            (v) =>
                this.sectionRefs[v]?.current?.getBoundingClientRect().bottom >
                window.innerHeight / 2
        );
    };

    render() {
        const { sectionRefs, props, state } = this;
        const { windowSize, theme, popoverVisible, projectToShow } = props;
        const { currentSection } = state;
        const headerItems = Object.keys(sections).map((section) => ({
            title: sections[section],
            onClick: () => this.changeSection(sections[section]),
            active: currentSection === sections[section],
        }));

        const smallScreen = isWidthDown('sm', windowSize.width);
        const mediumScreen = isWidthDown('md', windowSize.width);

        return (
            <Wrapper>
                <ProjectPopup
                    id='popup'
                    visible={popoverVisible}
                    project={projectToShow}
                    close={() => this.props.updatePopoverVisible(false)}
                    onExit={() => this.props.clearProjectToShow()}
                />
                <Header
                    visible={
                        !popoverVisible &&
                        currentSection !== sections.WELCOME &&
                        currentSection !== sections.CONTACT
                    }
                    useSidebar={mediumScreen}
                    theme={theme}
                    items={headerItems}
                />
                <Section
                    ref={sectionRefs[sections.WELCOME]}
                    style={{
                        color: theme.font,
                        backgroundColor: 'transparent',
                    }}
                >
                    <Animation backgroundColor={theme.backgroundPrimary} />
                    <Welcome />
                </Section>
                {this.state.renderEverything && (
                    <>
                        <Section
                            ref={sectionRefs[sections.ABOUT]}
                            style={{
                                color: theme.font,
                                backgroundColor: theme.backgroundPrimary,
                                minHeight: '110vh',
                                padding: smallScreen ? '10% 0' : 0,
                            }}
                        >
                            <About theme={theme} />
                            {/* <ShootingStars color={theme.backgroundSecondary} /> */}
                        </Section>
                        <Section
                            ref={sectionRefs[sections.PORTFOLIO]}
                            style={{
                                color: theme.font,
                                background: `linear-gradient(${theme.backgroundPrimary}, ${theme.backgroundSecondary} 100%)`,
                                justifyContent: 'flex-start',
                                paddingTop: '150px',
                            }}
                        >
                            <Portfolio />
                        </Section>
                        {/* <Section
                            ref={sectionRefs[sections.ARCHIVE]}
                            style={{
                                color: theme.font,
                                background: theme.backgroundSecondary,
                                justifyContent: 'flex-start',
                                paddingTop: '150px',
                            }}
                        >
                            <Archive />
                        </Section> */}
                        <Section
                            ref={sectionRefs[sections.CONTACT]}
                            style={{
                                color: theme.font,
                                backgroundColor: 'transparent',
                            }}
                        >
                            <svg
                                height='10vh'
                                width='100%'
                                viewBox='0 0 100 102'
                                version='1.1'
                                xmlns='http://www.w3.org/2000/svg'
                                preserveAspectRatio='none'
                            >
                                <path
                                    d='M0 0 L50 100 L100 0 Z'
                                    fill={theme.backgroundSecondary}
                                />
                            </svg>
                            {/* <ShootingStars color={theme.backgroundSecondary} /> */}
                            <Contact links={links} />
                        </Section>
                        <Footer
                            theme={theme}
                            copyrightMessage={
                                smallScreen
                                    ? copyrightMessage.split(
                                          copyrightMessageSplit
                                      )
                                    : copyrightMessage
                                          .split(copyrightMessageSplit)
                                          .join(' ')
                            }
                        />
                    </>
                )}

                <GlowingArrow
                    up={currentSection === sections.CONTACT}
                    stroke='white'
                    fill={
                        currentSection === sections.WELCOME || smallScreen
                            ? theme.backgroundPrimary
                            : 'transparent'
                    }
                    style={{
                        position: 'fixed',
                        right: 30,
                        bottom: 25,
                        backgroundColor: 'transparent',
                        opacity: currentSection === null ? 0 : 1,
                    }}
                    onClick={() =>
                        this.changeSection(this.getNextSection(currentSection))
                    }
                />
            </Wrapper>
        );
    }
}

const Wrapper = styled(FlexCenteredColumn)`
    height: 100%;
    width: 100%;
`;

const mapStateToProps = ({ sizes, themes, popover }) => {
    const { windowSize } = sizes;
    const { theme } = themes;
    const { popoverVisible, projectToShow } = popover;
    return { windowSize, theme, popoverVisible, projectToShow };
};

export default connect(mapStateToProps, {
    clearProjectToShow,
    updatePopoverVisible,
})(Home);
