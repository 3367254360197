import React, { Component } from 'react';

export class TriangleDown extends Component {
    render() {
        return (
            <svg
                width='52'
                height='45'
                viewBox='0 0 52 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                {...this.props}
            >
                <path
                    d='M51.1147 0.5L26 44L0.885265 0.5L51.1147 0.5Z'
                    stroke={this.props.style?.color}
                />
            </svg>
        );
    }
}
export class ArrowForward extends Component {
    render() {
        return (
            <svg
                width='41'
                height='46'
                viewBox='0 0 41 46'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                background={this.props.style?.backgroundColor}
                {...this.props}
            >
                <path
                    d='M40.1213 25.1213C41.2929 23.9497 41.2929 22.0503 40.1213 20.8787L21.0294 1.7868C19.8579 0.615224 17.9584 0.615224 16.7868 1.7868C15.6152 2.95837 15.6152 4.85786 16.7868 6.02944L33.7574 23L16.7868 39.9706C15.6152 41.1421 15.6152 43.0416 16.7868 44.2132C17.9584 45.3848 19.8579 45.3848 21.0294 44.2132L40.1213 25.1213ZM0 26L38 26V20L0 20L0 26Z'
                    fill={this.props.style?.color}
                />
            </svg>
        );
    }
}

export class ArrowBack extends Component {
    render() {
        return (
            <svg
                width='41'
                height='46'
                viewBox='0 0 41 46'
                xmlns='http://www.w3.org/2000/svg'
                background={this.props.style?.backgroundColor}
                {...this.props}
            >
                <path
                    d='M0.87868 20.8787C-0.292893 22.0503 -0.292893 23.9497 0.87868 25.1213L19.9706 44.2132C21.1421 45.3848 23.0416 45.3848 24.2132 44.2132C25.3848 43.0416 25.3848 41.1421 24.2132 39.9706L7.24264 23L24.2132 6.02944C25.3848 4.85786 25.3848 2.95837 24.2132 1.7868C23.0416 0.615224 21.1421 0.615224 19.9706 1.7868L0.87868 20.8787ZM41 20L3 20V26L41 26V20Z'
                    fill={this.props.style?.color}
                />
            </svg>
        );
    }
}

export class Exclamation extends Component {
    render() {
        return (
            <svg
                width='60'
                height='60'
                viewBox='0 0 60 60'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                {...this.props}
            >
                <circle
                    cx='30'
                    cy='30'
                    r='30'
                    fill={this.props.style?.backgroundColor}
                />
                <rect
                    x='27'
                    y='8'
                    width='6'
                    height='30'
                    rx='3'
                    fill={this.props.style?.color}
                />
                <circle cx='30' cy='48' r='4' fill={this.props.style?.color} />
            </svg>
        );
    }
}

export class ArrowSend extends Component {
    render() {
        return (
            <svg
                width='50'
                height='40'
                viewBox='0 0 50 40'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                {...this.props}
            >
                <path
                    d='M50 20L12.5 37.3205V2.67949L50 20Z'
                    fill={this.props.style?.color}
                />
                <path
                    d='M38.7568 20L12.5068 24.3301V15.6699L38.7568 20Z'
                    fill='white'
                />
            </svg>
        );
    }
}

export class Airplane extends Component {
    render() {
        return (
            <svg
                width='731'
                height='580'
                viewBox='0 0 731 580'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                {...this.props}
            >
                <path
                    d='M728.5 1.5L558 580L435.5 363L728.5 1.5Z'
                    fill='#0000EE'
                />
                <path
                    d='M726.5 4L437.5 361L169.5 408.5L726.5 4Z'
                    fill='#0000CC'
                />
                <path
                    d='M726.5 4L437.5 361L169.5 408.5L726.5 4Z'
                    fill='#0000CC'
                />
                <path d='M730 1L262.5 214L170.5 408L730 1Z' fill='#0000AA' />
                <path d='M730 1L262.5 214L170.5 408L730 1Z' fill='#0000AA' />
                <path
                    d='M731 0.5L258.5 226.5L0 157.5L731 0.5Z'
                    fill='#000088'
                />
            </svg>
        );
    }
}
