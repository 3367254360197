import { combineReducers } from 'redux';

import ObjectSizesReducer from './ducks/ObjectSizesDuck';
import ThemesReducer from './ducks/ThemesDuck';
import PopoverReducer from './ducks/PopoverDuck';

const createRootReducer = () =>
    combineReducers({
        sizes: ObjectSizesReducer,
        themes: ThemesReducer,
        popover: PopoverReducer,
    });

export default createRootReducer;
