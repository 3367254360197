/**
 * ScrollingImage gives the illusion of a scrolling set of images by translating the whole wrapper
 * and quickly changing image sources at the end of the animation.
 *
 * The prop hiddenImgOffsetPercent defines the space between the visible and hidden image.
 * It gives a greater gap between them while scrolling.
 */

import {
    React,
    Component,
    Proptypes,
    styled,
    FlexCentered,
} from '../../imports';

class ScrollingImage extends Component {
    state = {
        imgScrollVisible: false,
        currentImage: 0,
        scrolling: false,
        scrollingBackwards: false,
        unClicked: true,
    };
    imgWrapper = React.createRef();
    hiddenImage = React.createRef();
    scrollAnim = null;

    componentDidUpdate = (prevProps) => {
        // if srcs change, reset image num
        if (this.props.imgs.length !== prevProps.imgs.length) {
            this.setState({ currentImage: 0 });
        }
    };

    componentWillUnmount = () => {
        if (this.scrollAnim) this.scrollAnim.cancel();
    };

    _scroll = (options = {}) => {
        const {
            scrollEasing,
            scrollDuration,
            hiddenImgOffsetPercent,
        } = this.props;
        const { backwards } = options;
        const imgWrapperWidth = this.imgWrapper.current?.getBoundingClientRect()
            .width;
        const hiddenImageWidth = this.hiddenImage.current?.getBoundingClientRect()
            .width;

        // translate the whole image wrapper for the illusion of scrolling
        this.scrollAnim = this.imgWrapper.current.animate(
            [
                { transform: 'translateX(0px)' },
                {
                    transform: `translateX(${backwards ? '' : '-'}${
                        imgWrapperWidth * (1 + hiddenImgOffsetPercent / 100) -
                        (imgWrapperWidth - hiddenImageWidth) / 2
                    }px)`,
                },
            ],
            {
                duration: scrollDuration,
                iterations: 1,
                direction: 'normal',
                easing: scrollEasing,
            }
        );
        this.scrollAnim.pause();

        // once the scrolling has finished, update the image source for hidden and visible images
        // because hidden image will go back to hiding
        this.scrollAnim.onfinish = () =>
            this.setState(({ currentImage }) => ({
                currentImage: backwards
                    ? currentImage === 0
                        ? this.props.imgs.length - 1
                        : currentImage - 1
                    : currentImage === this.props.imgs.length - 1
                    ? 0
                    : currentImage + 1,
                scrolling: false,
                scrollingBackwards: false,
            }));

        this.scrollAnim.play();
    };

    render() {
        const { style, imgs, alt, id, onMouseEnter, onMouseLeave } = this.props;
        const {
            windowSize,
            wrapperStyle,
            scrollFont,
            scrollBG,
            hiddenImgOffsetPercent,
            scrollEasing,
            scrollDuration,
            ...rest
        } = this.props;
        const {
            currentImage,
            scrolling,
            scrollingBackwards,
            unClicked,
        } = this.state;
        const imgScrollVisible = this.state.imgScrollVisible || unClicked;

        return (
            <Wrapper
                style={{ ...wrapperStyle }}
                onMouseEnter={() => {
                    onMouseEnter();
                    this.setState({ imgScrollVisible: true });
                }}
                onMouseLeave={() => {
                    onMouseLeave();
                    this.setState({ imgScrollVisible: false });
                }}
            >
                <AnimatedWrapper ref={this.imgWrapper}>
                    <img
                        id={id}
                        alt={alt}
                        {...rest}
                        style={{
                            ...style,
                            objectFit: 'scale-down',
                        }}
                        src={imgs[currentImage]}
                    />
                    <img
                        id={id}
                        alt={alt}
                        {...rest}
                        ref={this.hiddenImage}
                        style={{
                            ...style,
                            position: 'absolute',
                            height: '100%',
                            top: 0,
                            objectFit: 'scale-down',
                            ...(scrollingBackwards
                                ? { right: `${100 + hiddenImgOffsetPercent}%` }
                                : { left: `${100 + hiddenImgOffsetPercent}%` }),
                        }}
                        src={
                            scrollingBackwards
                                ? currentImage === 0
                                    ? imgs[this.props.imgs.length - 1]
                                    : imgs[currentImage - 1]
                                : currentImage + 1 >= imgs.length
                                ? imgs[0]
                                : imgs[currentImage + 1]
                        }
                    />
                    <ImgScroll
                        left
                        visible={imgScrollVisible}
                        vanish={scrolling}
                        style={{ backgroundColor: scrollBG }}
                        onClick={() => {
                            if (imgScrollVisible && !scrolling)
                                this.setState(
                                    {
                                        scrolling: true,
                                        scrollingBackwards: true,
                                        unClicked: false,
                                    },
                                    () => this._scroll({ backwards: true })
                                );
                        }}
                    >
                        <Arrow scrollFont={scrollFont} />
                    </ImgScroll>
                    <ImgScroll
                        visible={imgScrollVisible}
                        vanish={scrolling}
                        style={{ backgroundColor: scrollBG }}
                        onClick={() => {
                            if (imgScrollVisible && !scrolling)
                                this.setState(
                                    { scrolling: true, unClicked: false },
                                    this._scroll
                                );
                        }}
                    >
                        <Arrow scrollFont={scrollFont} />
                    </ImgScroll>
                </AnimatedWrapper>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const AnimatedWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: visible;
`;

const ImgScroll = styled(FlexCentered)`
    position: absolute;
    top: 50%;
    bottom: 50%;
    ${({ left }) => (left ? 'left' : 'right')}: 0;
    height: 3.5em;
    width: 1.5em;
    padding-right: 0.5em;
    cursor: ${({ visible }) => (visible ? 'pointer' : 'default')};
    transform: translateX(
            ${({ visible, left }) => (visible ? 0 : left ? '-2em' : '2em')}
        )
        ${({ left }) => (left ? 'rotate(180deg)' : '')};
    opacity: ${({ vanish }) => (vanish ? 0 : 0.8)};
    transition: 0.5s transform;
    text-align: center;
    border-bottom-left-radius: 0.1em;
    border-top-left-radius: 0.1em;
`;

const Arrow = styled.div`
    border: solid ${({ scrollFont }) => scrollFont};
    border-width: 0 3px 3px 0;
    display: inline-block;
    height: 15px;
    width: 15px;
    transform: rotate(-45deg);
`;

ScrollingImage.propTypes = {
    onMouseEnter: Proptypes.func,
    onMouseLeave: Proptypes.func,
    windowSize: Proptypes.object,
    id: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
    alt: Proptypes.string,
    style: Proptypes.object,
    wrapperStyle: Proptypes.object,
    scrollDuration: Proptypes.number,
    scrollEasing: Proptypes.string,
    hiddenImgOffsetPercent: Proptypes.number,
};

const sampleId = Math.random();
ScrollingImage.defaultProps = {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    windowSize: {},
    id: `${sampleId}`,
    alt: `scrolling-img-${sampleId}`,
    style: {},
    wrapperStyle: {},
    scrollDuration: 500,
    scrollEasing: 'ease-out',
    hiddenImgOffsetPercent: 50,
};

export default ScrollingImage;
