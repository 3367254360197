/**
 * Project display project data defined in project-constants. Projects take the following form:
 * {
 *  id: String,
 *  title: String,
 *  imgs: [String]
 *  thumbnail: String
 * }
 */

import {
    React,
    Component,
    Proptypes,
    connect,
    styled,
    FlexCentered,
    Skeleton,
} from '../../imports';
import { OneSizeImage } from '../common';

class Project extends Component {
    state = { imageLoading: true };

    render() {
        const { props, state } = this;
        const {
            project,
            selectedID,
            onSelect,
            onDeselect,
            showPopover,
            windowSize,
            imageStyle,
            skeletonColor,
        } = props;
        const { imageLoading } = state;
        const { id, title, imgs, thumbnail } = project;

        const image = (
            <Image
                id={`img-${id}`}
                imgs={imgs}
                windowSize={windowSize}
                alt={title}
                src={thumbnail}
                onLoad={() => {
                    this.setState({ imageLoading: false });
                }}
            />
        );

        return (
            <>
                {imageLoading ? (
                    <Skeleton
                        variant='rect'
                        height='100%'
                        width='100%'
                        color={skeletonColor}
                        style={{ borderRadius: 10 }}
                    >
                        {image}
                    </Skeleton>
                ) : (
                    <Wrapper
                        style={imageStyle || {}}
                        fade={selectedID && selectedID !== id}
                        selected={selectedID && selectedID === id}
                        onMouseEnter={() => {
                            onSelect();
                        }}
                        onMouseLeave={() => {
                            onDeselect();
                        }}
                        onClick={() => {
                            showPopover(project);
                            onDeselect();
                        }}
                    >
                        {image}
                    </Wrapper>
                )}
            </>
        );
    }
}

const Wrapper = styled(FlexCentered)`
    position: relative;
    height: 100%;
    width: 100%;

    transition: 0.5s opacity;
    opacity: ${({ fade }) => (fade ? 0.4 : 1)};
    z-index: ${({ fade }) => (fade ? 0 : 10001)};

    transition: 0.5s;
    transform: translateY(${({ selected }) => (selected ? -20 : 0)}px);
    cursor: pointer;
`;

const Image = styled(OneSizeImage)`
    flex: 1;
`;

Project.propTypes = {
    project: Proptypes.object,
    windowSize: Proptypes.object,
    imageStyle: Proptypes.object,

    skeletonColor: Proptypes.string,
    selectedID: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),

    onSelect: Proptypes.func,
    onDeselect: Proptypes.func,
    showPopover: Proptypes.func,
};

Project.defaultProps = {
    project: {},
    windowSize: {},
    imageStyle: {},

    skeletonColor: 'white',
    selectedID: Math.random(),

    onSelect: () => {},
    onDeselect: () => {},
    showPopover: () => {},
};

const mapStateToProps = ({ sizes }) => {
    const { windowSize } = sizes;
    return { windowSize };
};

export default connect(mapStateToProps, {})(Project);
