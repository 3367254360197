/**
 * Header takes in an array of items as props. Items have the following form:
 * {
 *  title: String,
 *  onClick: Function, (usually navigation)
 *  active: Boolean,
 * }
 */

import { React, Proptypes, styled, FlexCentered } from '../../imports';

const sidebarWidth = '300px';
const sidebarOpenerWidth = 30;

const Header = ({
    items,
    visible,
    theme,
    height,
    useSidebar,
    contentRight,
}) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    if (!useSidebar) {
        return (
            <Wrapper visible={visible} theme={theme} height={height}>
                {items.map(({ title, onClick, active }, i) => (
                    <Item
                        key={title}
                        style={{ marginLeft: i === 0 ? 0 : 60 }}
                        active={active}
                        theme={theme}
                        onClick={onClick}
                    >
                        {title}
                    </Item>
                ))}
                <ContentRightWrapper>{contentRight}</ContentRightWrapper>
            </Wrapper>
        );
    } else {
        return (
            <>
                <SidebarOpener
                    color={theme.header}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    clicked={sidebarOpen}
                />
                <SidebarBackground
                    onClick={() => setSidebarOpen(false)}
                    style={{
                        transform: `scale(${sidebarOpen ? 1 : 0.98})`,
                        opacity: `${sidebarOpen ? 1 : 0}`,
                        zIndex: sidebarOpen ? 1000000 : -1,
                    }}
                />
                <Sidebar
                    theme={theme}
                    style={{
                        transform: `translateX(${
                            sidebarOpen ? 0 : sidebarWidth
                        })`,
                    }}
                >
                    {items.map(({ title, onClick, active }, i) => (
                        <Item
                            key={title}
                            active={active}
                            theme={theme}
                            onClick={onClick}
                            style={{ marginTop: i === 0 ? 0 : 40 }}
                        >
                            {title}
                        </Item>
                    ))}
                    <FlexCentered style={{ marginTop: 40 }}>
                        {contentRight}
                    </FlexCentered>
                </Sidebar>
            </>
        );
    }
};

const Wrapper = styled(FlexCentered)`
    z-index: 1000000;

    position: fixed;
    top: 0;
    left: 0;

    transition: 0.2s;
    height: ${({ height }) => height}px;
    width: 100%;
    transform: translateY(
        ${({ visible, height }) => (visible ? 0 : -height - 1)}px
    );

    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.backgroundSecondary};
`;

const ContentRightWrapper = styled(FlexCentered)`
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    padding: 0 20px;
`;

const Item = styled.p`
    margin: 0;
    font-size: 18px;
    cursor: pointer;
    color: ${({ active, theme }) =>
        active ? theme.backgroundSecondary : theme.header};

    &:hover {
        color: ${({ theme }) => theme.backgroundSecondary};
    }
`;

const SidebarBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;

    height: 100%;
    width: 100%;

    transition: 0.5s;
    background-color: rgba(0, 0, 0, 0.5);
`;

const Sidebar = styled(FlexCentered)`
    display: flex;
    flex-flow: column;

    position: fixed;
    top: 0;
    right: 0;

    z-index: 1000001;
    box-sizing: border-box;
    height: 100%;
    width: ${sidebarWidth};
    padding: 100px 20px;

    transition: 0.5s;
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-left: 1px solid ${({ theme }) => theme.backgroundSecondary};
`;

const SidebarOpener = styled.div`
    z-index: 1000002;
    position: fixed;
    top: 30px;
    right: 20px;

    height: 5px;
    width: ${sidebarOpenerWidth}px;

    background-color: ${({ color }) => color};

    transition: 0.5s;
    transform: rotate(${({ clicked }) => (clicked ? -45 : 0)}deg);

    &::before {
        content: ' ';
        position: absolute;
        top: ${({ clicked }) => (clicked ? 0 : -10)}px;
        left: 0px;
        right: 0px;

        height: 5px;
        width: ${sidebarOpenerWidth}px;

        background-color: ${({ color }) => color};
        transform: scaleX(${({ clicked }) => (clicked ? 0 : 1)});
        transition: 0.5s;
    }

    &::after {
        content: ' ';
        position: absolute;
        top: ${({ clicked }) => (clicked ? 0 : 10)}px;
        left: 0px;
        right: 0px;

        height: 5px;
        width: ${sidebarOpenerWidth}px;

        background-color: ${({ color }) => color};
        transform: rotate(${({ clicked }) => (clicked ? -90 : 0)}deg);
        transition: 0.5s;
    }
`;

Header.propTypes = {
    items: Proptypes.array.isRequired,
    visible: Proptypes.bool,
    useSidebar: Proptypes.bool,
    theme: Proptypes.object,
    height: Proptypes.number,
};

Header.defaultProps = {
    items: [],
    visible: true,
    useSidebar: false,
    theme: {},
    height: 60,
};

export default Header;
