/*
 */

import { React, Component, styled } from '../../imports';
import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    MeshBasicMaterial,
    Mesh,
    SphereGeometry,
    Raycaster,
    Vector2,
} from 'three';

export default class Animation extends Component {
    ref = React.createRef();
    state = { moving: false };

    componentDidMount = () => {
        this._setupAnimation();
        window.addEventListener('resize', this._updateRendererSize);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this._updateRendererSize);
    };

    render() {
        return <Wrapper ref={this.ref} />;
    }

    _updateRendererSize = () => {
        this.camera.aspect =
            this.ref.current.clientWidth / this.ref.current.clientHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(
            this.ref.current.clientWidth,
            this.ref.current.clientHeight
        );
    };

    _setupAnimation = () => {
        // set up scene and render (always first thing to do)
        this.scene = new Scene();
        this.camera = new PerspectiveCamera(
            75, // field of view (extent of what is seen on screen, in degrees)
            this.ref.current.clientWidth / this.ref.current.clientHeight, // aspect ratio
            0.1, // near
            1000 // far
        );
        this.renderer = new WebGLRenderer();
        this.renderer.setSize(
            this.ref.current.clientWidth,
            this.ref.current.clientHeight
        );
        this.renderer.setClearColor(this.props.backgroundColor, 1);
        this.ref.current.appendChild(this.renderer.domElement);

        // this.controls = new OrbitControls(
        //     this.camera,
        //     this.renderer.domElement
        // );
        // this.controls.target.set(0, 0, 0);
        // this.controls.update();

        // add a cube to the scene
        // this.geometry = new BoxGeometry();
        // this.material = new MeshBasicMaterial({ color: 0xffff00 });
        // this.cube = new Mesh(this.geometry, this.material);
        // this.scene.add(this.cube);

        this.circles = this._generateCircles();
        // this.circles = this._generateCircles({
        //     sphereRadius: 300,
        //     numMiniSpheres: 400,
        // });

        this.camera.position.z = 0;

        // start animation loop
        animationLoop({
            func: () => {
                this.camera.rotation.x += 0.0005;
                this.camera.rotation.y += 0.0005;
                this.renderer.render(this.scene, this.camera);
            },
        });
    };

    _generateCircles = ({ sphereRadius = 600, numMiniSpheres = 600 } = {}) => {
        // let color = 0x000000;
        let color = 0x123c69;
        const points = genPointsOnSphereSurface(numMiniSpheres, sphereRadius);
        const circles = points.reduce((acc, { x, y, z }) => {
            const geometry = new SphereGeometry();
            const material = new MeshBasicMaterial({
                color,
                // 0 +
                // ((toHex(sphereRadius * 2) - 0x000000) *
                //     (toHex(x) + toHex(y) - 0x000000)) /
                //     (0x123c69 - 0x000000),
                // color: random(0, 0xffffff),
            });
            const circle = new Mesh(geometry, material);
            circle.translateX(x);
            circle.translateY(y);
            circle.translateZ(z);
            this.scene.add(circle);
            return acc.concat(circle);
        }, []);

        circles.sort((a, b) => a.position.z - b.position.z);
        // set colors based on index in sorted list
        const numDivisions = 20;
        circles.forEach((circle, i) => {
            if ((numMiniSpheres / numDivisions) % i === 0) {
                color = parseInt(
                    lightenDarkenColor(color.toString(16), 10),
                    16
                );
            }
            circle.material.color.setHex(color);
        });
        return circles;
    };

    getIntersections = (event) => {
        const mouse = new Vector2();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        const raycaster = new Raycaster();
        raycaster.setFromCamera(mouse, this.camera);
        const intersects = raycaster.intersectObjects(this.circles);
        return intersects;
    };

    onDocumentMouseMove = (event) => {
        const intersects = this.getIntersections(event);
        if (intersects.length > 0) {
            document.body.style.cursor = 'pointer';
        } else {
            document.body.style.cursor = 'default';
        }
    };

    onClick = (event) => {
        const intersects = this.getIntersections(event);
        intersects.forEach(({ object }) => {
            object.material.color.setHex('#ff0000');
        });
    };
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
`;

const genPointsOnSphereSurface = (numPoints = 10, sphereRadius = 10) => {
    let points = [];
    for (let i = 0; i < numPoints; i++) {
        const theta = 2 * Math.PI * Math.random();
        const phi = Math.acos(1 - 2 * Math.random());
        const x = Math.floor(sphereRadius * Math.sin(phi) * Math.cos(theta));
        const y = Math.floor(sphereRadius * Math.sin(phi) * Math.sin(theta));
        const z = Math.floor(sphereRadius * Math.cos(phi));
        points.push({ x, y, z });
    }
    return points;
};
const lightenDarkenColor = (col, amt) => {
    var usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const animationLoop = ({ func, breakCondition }) => {
    const repeat = () => {
        if (breakCondition && breakCondition()) return;
        func();
        requestAnimationFrame(repeat);
    };
    repeat();
};
