/**
 * Archive is the screen displaying smaller projects.
 */

import {
    React,
    Component,
    connect,
    styled,
    responsiveFontSize,
    smallerProjects,
    isWidthUp,
    FlexCentered,
    FlexCenteredColumn,
} from '../../imports';

import {
    updatePopoverVisible,
    setProjectToShow,
} from '../../ducks/PopoverDuck';
import { Carousel, ScrollIntoView } from '../common';

class Archive extends Component {
    state = {
        inView: false,
        viewAll: false,
        selectedID: null,
    };
    contentRef = React.createRef();
    anim = null;

    componentWillUnmount = () => {
        if (this.anim?.cancel) this.anim.cancel();
    };

    _switchView = () => {
        this.anim = this.contentRef.current.animate(
            [{ opacity: 1 }, { opacity: 0 }],
            { duration: 500, fill: 'forwards' }
        );
        this.anim.pause();
        this.anim.onfinish = () =>
            this.setState(
                ({ viewAll }) => ({ viewAll: !viewAll }),
                () => {
                    this.contentRef.current.animate(
                        [{ opacity: 0 }, { opacity: 1 }],
                        { duration: 500, fill: 'forwards', delay: 250 }
                    );
                }
            );
        this.anim.play();
    };

    render() {
        const { windowSize, theme } = this.props;
        const { inView, viewAll } = this.state;

        const mediumScreen = isWidthUp('md', windowSize.width);
        const largeScreen = isWidthUp('lg', windowSize.width);

        const appearStyle = {
            transform: inView ? 'none' : 'translateX(400px)',
            opacity: inView ? 1 : 0,
            transition: '0.5s transform, 0.5s opacity',
        };

        const carouselSize = mediumScreen ? 400 : 200;
        const projectSize = largeScreen ? 300 : mediumScreen ? 200 : 100;

        return (
            <Wrapper
                offsetFromTop={window.innerHeight / 1.5}
                onScrollIntoView={() => this.setState({ inView: true })}
            >
                <Title style={{ ...appearStyle, color: theme.header }}>
                    The Archive
                </Title>
                {mediumScreen ? (
                    <SubTitle
                        style={{ ...appearStyle, transitionDelay: '0.1s' }}
                    >
                        I'm always making stuff! Check out some of my smaller
                        projects :-)
                    </SubTitle>
                ) : (
                    <>
                        <SubTitle
                            style={{
                                ...appearStyle,
                                marginBottom: 10,
                                transitionDelay: '0.1s',
                            }}
                        >
                            I'm always making stuff!
                        </SubTitle>
                        <SubTitle
                            style={{ ...appearStyle, transitionDelay: '0.15s' }}
                        >
                            Check out some of my smaller projects
                        </SubTitle>
                    </>
                )}
                <ContentWrapper
                    ref={this.contentRef}
                    style={{
                        ...appearStyle,
                        transform: inView ? 'none' : 'translateY(-50px)',
                        transitionDelay: '0.25s',
                    }}
                >
                    {viewAll ? (
                        <FlexCentered
                            style={{
                                flexFlow: 'row wrap',
                                width: 3 * projectSize + 3 * 10 * 2,
                            }}
                        >
                            {smallerProjects.map((project) => (
                                <Image
                                    key={project.title}
                                    alt={project.title}
                                    src={project.thumbnail}
                                    style={{
                                        backgroundColor:
                                            theme.backgroundPrimary,
                                        height: projectSize,
                                        width: projectSize,
                                        margin: 10,
                                    }}
                                    onClick={() =>
                                        this.props.setProjectToShow(project)
                                    }
                                />
                            ))}
                        </FlexCentered>
                    ) : (
                        <Carousel
                            style={{
                                marginBottom: '2em',
                                height: carouselSize,
                                width: carouselSize,
                                boxSizing: 'border-box',
                            }}
                            items={smallerProjects.map((project) => ({
                                onClick: () =>
                                    this.props.setProjectToShow(project),
                                component: (
                                    <Image
                                        alt={project.title}
                                        src={project.thumbnail}
                                        height='100%'
                                        width='100%'
                                        style={{
                                            backgroundColor:
                                                theme.backgroundPrimary,
                                        }}
                                    />
                                ),
                            }))}
                        />
                    )}
                    <div
                        style={{
                            ...appearStyle,
                            transform: inView ? 'none' : 'translateY(-50px)',
                            transitionDelay: '0.5s',
                        }}
                    >
                        <Button color={theme.header} onClick={this._switchView}>
                            {viewAll ? 'View Less' : 'View All'}
                        </Button>
                    </div>
                </ContentWrapper>
            </Wrapper>
        );
    }
}

const Wrapper = styled(ScrollIntoView)`
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 10%;
    padding-bottom: 20vh;
`;

const Title = styled.h1`
    margin: 0;
    margin-bottom: 10px;
    font-size: ${responsiveFontSize({ min: 32, max: 50 })};
    color: ${({ color }) => color};
`;

const SubTitle = styled.h3`
    margin: 0;
    margin-bottom: 3em;
    font-size: ${responsiveFontSize({ min: 18, max: 22 })};
    font-weight: 500;
`;

const ContentWrapper = styled(FlexCenteredColumn)`
    width: 100%;
`;

const Button = styled.div`
    border: 1px solid ${({ color }) => color};
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 30px;

    text-align: center;
    font-weight: bold;
    font-size: 18px;

    cursor: pointer;
    transition: 0.5s;

    color: ${({ color }) => color};
    background-color: transparent;
    &:hover {
        transform: scale(0.9);
    }
`;

const Image = styled.img`
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        transform: scale(1.1);
    }
`;

const mapStateToProps = ({ sizes, themes, popover }) => {
    const { windowSize } = sizes;
    const { theme } = themes;
    const { popoverVisible } = popover;
    return {
        windowSize,
        theme,
        popoverVisible,
    };
};

export default connect(mapStateToProps, {
    updatePopoverVisible,
    setProjectToShow,
})(Archive);
