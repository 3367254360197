import { theme } from '../imports';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SET_THEME: 'SET_THEME',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    theme: { ...theme },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_THEME:
            return { ...state, theme: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const setTheme = (theme) => {
    document.body.style.backgroundColor = theme.backgroundPrimary;
    return {
        type: types.SET_THEME,
        payload: theme,
    };
};
