/**
 * OneSizeImage caches the dimensions of the first image loaded, so that when the src changes the img dimensions do not jump to change.
 */

import { React, Component, Proptypes } from '../../imports';

class OneSizeImage extends Component {
    state = {
        imgDimensions: {},
        naturalImageDimensions: {},
        firstLoad: true,
    };

    ref = React.createRef();
    timeoutId = null;
    intervalId = null;

    componentDidMount = () => {
        this._getImageNaturalDimensions();
    };

    componentDidUpdate = (prevProps) => {
        const windowResized =
            prevProps.windowSize.width !== this.props.windowSize.width ||
            prevProps.windowSize.height !== this.props.windowSize.height;
        if (windowResized) {
            this._cleanup();
            this._updateDimensions();
        }
        if (prevProps.src && !this.props.src) {
            this.setState({ firstLoad: true });
        }
    };

    componentWillUnmount = () => {
        this._cleanup();
    };

    _getImageNaturalDimensions = () => {
        this.intervalId = setInterval(() => {
            if (
                this.ref.current?.naturalWidth &&
                this.ref.current?.naturalHeight
            ) {
                clearInterval(this.intervalId);
                this.intervalId = null;
                this.setState({
                    naturalImageDimensions: {
                        width: this.ref.current.naturalWidth,
                        height: this.ref.current.naturalHeight,
                    },
                });
            }
        }, 10);
    };

    _updateDimensions = () => {
        this.setState({ imgDimensions: {} }, () => {
            this.timeoutId = setTimeout(this._setDimensions, 500);
        });
    };

    _setDimensions = () => {
        const { width, height } = this.ref.current.getBoundingClientRect();
        this.setState({ imgDimensions: { width, height } });
    };

    _cleanup = () => {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
    };

    render() {
        const { props, state, ref } = this;
        const {
            id,
            alt,
            src,
            style,
            initialWidth,
            initialHeight,
            windowSize,
            onLoad,
            ...rest
        } = props;
        const { imgDimensions } = state;

        return (
            <img
                id={id}
                alt={alt}
                ref={ref}
                src={src}
                {...rest}
                style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                    ...style,
                    width: imgDimensions.width || initialWidth,
                    height: imgDimensions.height || initialHeight,
                    transition: imgDimensions.width || style.transition,
                }}
                onLoad={() => {
                    onLoad();
                    if (this.state.firstLoad) {
                        this.setState(
                            { firstLoad: false },
                            this._updateDimensions
                        );
                    } else if (
                        !this.state.imgDimensions.width ||
                        !this.state.imgDimensions.height
                    ) {
                        this._setDimensions();
                    }
                }}
            />
        );
    }
}

OneSizeImage.propTypes = {
    onLoad: Proptypes.func,
    style: Proptypes.object,
    windowSize: Proptypes.object,

    id: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
    alt: Proptypes.string,
    src: Proptypes.string,

    initialWidth: Proptypes.string,
    initialHeight: Proptypes.string,
};

const id = Math.random();
OneSizeImage.defaultProps = {
    onLoad: () => {},
    style: {},
    windowSize: {},

    id: `${id}`,
    alt: `one-size-img-${id}`,
    src: '',

    initialWidth: 'auto',
    initialHeight: 'auto',
};

export default OneSizeImage;
