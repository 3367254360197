/**
 * Welcome is the first screen a user will view.
 */

import { React, connect, styled, responsiveFontSize, FlexCenteredColumn, isWidthDown } from '../../imports';

import { setTheme } from '../../ducks/ThemesDuck';
import { ChangingImage, ScrollIntoView } from '../common';
import fancyPic from '../../assets/img/me1.jpg';
import fancyPic2 from '../../assets/img/me_faded.png';

const greeting = 'Hello!';
const about = "I'm Griffin. I'm a software engineer at AWS for the Organizations Policies platform.";

const Welcome = ({ theme, windowSize, setTheme }) => {
    const mediumScreen = isWidthDown('md', windowSize.width);
    const [imgLoaded, setImgLoaded] = React.useState(false);
    const [inView, setInView] = React.useState(false);
    const ready = imgLoaded && inView;
    return (
        <Wrapper
            as={ScrollIntoView}
            persist
            onScrollIntoView={() => setInView(true)}
            onScrollOutOfView={() => setInView(false)}
            offsetFromTop={mediumScreen ? window.innerHeight / 2 : window.innerHeight / 1.5}
            style={{
                flexFlow: !mediumScreen ? 'row' : 'column-reverse',
                opacity: ready ? 1 : 0,
                transform: `translateY(${ready ? 0 : -60}px)`,
            }}
        >
            {/* <ThemePalette themes={themes} setTheme={setTheme}/> */}
            <FlexCenteredColumn
                style={{
                    alignItems: 'flex-start',
                    marginRight: mediumScreen ? 0 : '4em',
                    flex: mediumScreen ? '' : 1,
                }}
            >
                <h1
                    style={{
                        textAlign: 'center',
                        color: theme.header,
                        width: '100%',
                        marginBottom: 0,
                        fontSize: responsiveFontSize({
                            min: 32,
                            max: 80,
                        }),
                    }}
                >
                    {greeting}
                </h1>
                <p
                    style={{
                        textAlign: 'center',
                        fontSize: responsiveFontSize({
                            min: 16,
                            max: 30,
                        }),
                        color: theme.subtitle,
                    }}
                >
                    {about}
                    <br />
                    {'Nice to meet ya :)'}
                </p>
            </FlexCenteredColumn>
            <ChangingImage
                style={{
                    flex: mediumScreen ? '' : 1,
                    boxShadow: `0.5em 0.5em 0.7em 2px ${theme.imgShadow}`,
                    marginTop: mediumScreen ? 40 : 0,
                    maxWidth: '50%',
                    backgroundColor: 'transparent',
                }}
                title='Griffin Kupsaw'
                img1={fancyPic}
                img2={fancyPic2}
                onLoad={() => setImgLoaded(true)}
            />
        </Wrapper>
    );
};

const Wrapper = styled(FlexCenteredColumn)`
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    padding: 0 10%;

    transition: 1s;
`;

const mapStateToProps = ({ sizes, themes }) => {
    const { windowSize } = sizes;
    const { theme } = themes;
    return { windowSize, theme };
};

export default connect(mapStateToProps, { setTheme })(Welcome);
